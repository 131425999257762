<template>

<!-- Header -->	
    <header class="header-container">
      <div class="flex items-center">
        <router-link to="/dashboard">
        <ChevronLeftIcon class="flex items-center lg:hidden chevron-header-dark -ml-2" />
      </router-link>
        <h1 class="text-page-title-dark">Spotlights</h1>
      </div>  
    
      <div class="flex">
        <info-icon>
          <template v-slot:content>
          <p class="introcard-title-text">
            Your Spotlights
          </p>
          <p class="introcard-body-text">
            Spotlights are questions your therapist gives to you to answer in your own time. It helps you to reflect on material that comes up and gain a deeper insight into yourself. 
          </p>  
          </template>
        </info-icon>
      </div>         
  </header>
<!-- END Header -->	

<loading-overlay :loading="loading"></loading-overlay>

<div class="overflow-x-hidden">
<main v-if="!loading" class="main-layout" data-aos="slide-left" data-aos-once="true" data-aos-duration="250">
  <div class="flex justify-between">
    <div class="mb-2 flex items-center text-page-subtitle-dark">To Do</div>
  </div>
<!--Spotlights-->
    <div v-if="todo.length" class="grid md:grid-cols-2">
      <spotlight-card :spotlights="todo"/>
    </div>
    <p v-else class="card-no-entries">
      There are currently no 'Spotlights' in your ToDo list.
    </p>
<!--Title-->
    <div v-if="archive.length">
      <div class="flex justify-between mt-12">
        <div class="mb-2 flex items-center text-page-subtitle-dark">Archive</div>
      </div>
      <div class="grid md:grid-cols-2">
        <spotlight-card :spotlights="archive"/>
      </div>
    </div>
  </main>   
</div> 
</template>

<script setup>
  
  import { ref } from 'vue'
  import SpotlightCard from '../components/SpotlightCard';
  import { ChevronLeftIcon } from '@heroicons/vue/24/outline'
  import InfoIcon from '../components/InfoIcon';
  import { sendPost } from '../components/entities/functions.js';
  import LoadingOverlay from '../components/LoadingOverlay';
  

</script>

<script>
export default {
  data: function () {
    return {
      todo: [],
      archive: [],
      loading: false
    }
  },
  mounted() {
    this.getContent();
  },

  methods: {
    async getContent() {
      this.loading = true;
      const response = await sendPost('/api/me/spotlights');
      this.todo = response.todo;
      this.archive = response.archive;
      this.loading = false;
    },
  }
}
</script>
