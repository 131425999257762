<template>
  <div class="for-individuals-page">

<!-- Hero Section -->
    <BlockHero
      headline="Get in Touch"
      subheadline="I’m here to answer any questions you have or help you get started with therapy. Reach out, and I’ll respond as soon as possible."
      bgClass="bg-gray-800"
      theme="dark"
      
    />

    <section class="bg-white py-12 px-6">
  <div class="container mx-auto flex flex-col md:flex-row items-center justify-between md:px-12">
    
    <!-- Image -->
    <div class="md:w-2/5 flex justify-center mb-8 md:mb-0 md:pr-8">
      <img src="/img/website/me-4.jpg" alt="Tom Mitchell" class="max-w-full h-auto">
    </div>

    <!-- Contact Info -->
    <div class="md:w-3/5 text-center md:text-left space-y-6">
      <!-- Name and Title -->
      <div>
        <h3 class="font-semibold text-xl text-primary-600">Tom Mitchell</h3>
        <p class="ws-text-body-base text-gray-600">Founder & Therapist at Youlio</p>
        <p class="text-gray-700 font-bold text-sm tracking-wide">(MSc, PGDip, BACP, BAPPS)</p>
      </div>
      
      <!-- Divider -->
      <hr class="border-gray-200 my-6" />

      <!-- Email for Therapy Services -->
      <div class="text-left md:text-left">
        <h4 class="ws-text-body-base-bold text-gray-800">For Therapy Services</h4>
        <p class="tws-text-body-base text-gray-600">tom@youliohealth.com</p>
      </div>

      <!-- General Inquiries -->
      <div class="text-left md:text-left">
        <h4 class="ws-text-body-base-bold text-gray-800">For General Enquiries</h4>
        <p class="ws-text-body-base text-gray-600">hello@youliohealth.com</p>
      </div>
    </div>
  </div>
</section>


<!-- SECTION-Social -->
<section class="bg-primary-600 py-32 px-6">
  <div class="container mx-auto text-center">
    <h2 class="ws-text-heading-md text-white mb-4">Connect with Us On Social</h2>
    <p class="ws-text-body-base text-white mb-6">Follow us on social media for the latest updates and insights.</p>
    <div class="flex justify-center space-x-6">
      
       <!-- Instagram -->
       <a href="https://instagram.com/youliohealth" target="_blank" class="text-white hover:text-pink-600">
        <svg class="w-8 h-8" fill="currentColor" viewBox="0 0 24 24">
          <path d="M12 2.163c3.204 0 3.584.012 4.85.07 1.17.054 1.96.24 2.42.411.61.22 1.03.482 1.482.935.453.452.716.873.935 1.482.171.459.357 1.25.411 2.42.058 1.266.07 1.645.07 4.85s-.012 3.584-.07 4.85c-.054 1.17-.24 1.96-.411 2.42-.22.61-.482 1.03-.935 1.482-.452.453-.873.716-1.482.935-.459.171-1.25.357-2.42.411-1.266.058-1.645.07-4.85.07s-3.584-.012-4.85-.07c-1.17-.054-1.96-.24-2.42-.411-.61-.22-1.03-.482-1.482-.935-.453-.452-.716-.873-.935-1.482-.171-.459-.357-1.25-.411-2.42-.058-1.266-.07-1.645-.07-4.85s.012-3.584.07-4.85c.054-1.17.24-1.96.411-2.42.22-.61.482-1.03.935-1.482.452-.453.873-.716 1.482-.935.459-.171 1.25-.357 2.42-.411 1.266-.058 1.645-.07 4.85-.07zm0-2.163c-3.262 0-3.667.012-4.947.07-1.28.059-2.164.272-2.92.579-.8.321-1.47.744-2.139 1.413-.669.669-1.092 1.339-1.413 2.139-.307.756-.52 1.64-.579 2.92-.058 1.28-.07 1.685-.07 4.947s.012 3.667.07 4.947c.059 1.28.272 2.164.579 2.92.321.8.744 1.47 1.413 2.139.669.669 1.339 1.092 2.139 1.413.756.307 1.64.52 2.92.579 1.28.058 1.685.07 4.947.07s3.667-.012 4.947-.07c1.28-.059 2.164-.272 2.92-.579.8-.321 1.47-.744 2.139-1.413.669-.669 1.092-1.339 1.413-2.139.307-.756.52-1.64.579-2.92.058-1.28.07-1.685.07-4.947s-.012-3.667-.07-4.947c-.059-1.28-.272-2.164-.579-2.92-.321-.8-.744-1.47-1.413-2.139-.669-.669-1.339-1.092-2.139-1.413-.756-.307-1.64-.52-2.92-.579-1.28-.058-1.685-.07-4.947-.07zm0 5.838a6.162 6.162 0 100 12.325 6.162 6.162 0 000-12.325zm0 10.162a3.999 3.999 0 110-7.998 3.999 3.999 0 010 7.998zm6.406-11.845a1.44 1.44 0 11-2.88 0 1.44 1.44 0 012.88 0z"/>
        </svg>
      </a>
        <!-- Facebook -->
        <a href="https://facebook.com/youliohealth" target="_blank" class="text-white hover:text-blue-600">
        <svg class="w-8 h-8" fill="currentColor" viewBox="0 0 24 24">
          <path d="M22.675 0h-21.35c-.731 0-1.325.594-1.325 1.325v21.351c0 .73.594 1.324 1.325 1.324h11.494v-9.294h-3.125v-3.622h3.125v-2.672c0-3.1 1.894-4.788 4.659-4.788 1.325 0 2.462.099 2.793.143v3.24l-1.918.001c-1.504 0-1.796.715-1.796 1.763v2.313h3.587l-.467 3.622h-3.12v9.294h6.112c.73 0 1.324-.594 1.324-1.324v-21.35c0-.731-.594-1.325-1.324-1.325z"/>
        </svg>
      </a>
      <!-- LinkedIn -->
      <a href="https://www.linkedin.com/company/101995990" target="_blank" class="text-white hover:text-blue-700">
        <svg class="w-8 h-8" fill="currentColor" viewBox="0 0 24 24">
          <path d="M19 0h-14c-2.76 0-5 2.24-5 5v14c0 2.76 2.24 5 5 5h14c2.76 0 5-2.24 5-5v-14c0-2.76-2.24-5-5-5zm-11.66 19.27h-2.69v-8.36h2.69v8.36zm-1.34-9.52c-.85 0-1.55-.7-1.55-1.55s.7-1.55 1.55-1.55 1.55.7 1.55 1.55-.7 1.55-1.55 1.55zm12.66 9.52h-2.69v-4.5c0-1.07-.02-2.44-1.49-2.44s-1.72 1.16-1.72 2.37v4.57h-2.69v-8.36h2.58v1.14h.04c.36-.69 1.23-1.41 2.54-1.41 2.72 0 3.23 1.79 3.23 4.11v4.52z"/>
        </svg>
      </a>
     
    </div>
  </div>
</section>

 <!-- SECTION-Location -->
<section class="bg-white pt-16 sm:pt-24 pb-24 sm:pb-36 px-6">
  <div class="container mx-auto text-center">
    <div class="space-y-4">
      <!-- Address -->
      <div>
        <h3 class="ws-text-heading-sm text-gray-800 mb-2">Our Location</h3>
        <p class="ws-text-body-base text-gray-600">Senna Building, Gorsuch Pl,</p>
        <p class="ws-text-body-base text-gray-600">London E2 8JF</p>
      </div>
      <!-- Google Maps Embed -->
      <div class="mt-8">
        <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4964.1413802634215!2d-0.07742582327043876!3d51.530263259032644!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48761cb94fae2b0f%3A0x9370398596a1fb5!2sLondon%20E2%208JF!5e0!3m2!1sen!2suk!4v1725037320802!5m2!1sen!2suk"          width="100%"
          height="400"
          style="border:0;"
          allowfullscreen=""
          loading="lazy"
          class="rounded-lg shadow-md"
        ></iframe>
      </div>
    </div>
  </div>
</section>

  </div>
</template>

<script setup>
      
import BlockCTA from '../../website/components/BlockCTA' ;
import BlockHero from '../../website/components/BlockHero' ;
import BlockContent from '../../website/components/BlockContent' ;
import BlockTestimonial from '../../website/components/BlockTestimonial' ;
import CardContent from '../../website/components/CardContent' ;
import DisclosureContent from '../../website/components/DisclosureContent' ;
import BlockStructureTitle from '../../website/components/BlockStructureTitle' ;
import { KeyIcon } from '@heroicons/vue/24/outline'
import { ChevronRightIcon } from '@heroicons/vue/20/solid'   

</script>

<script>
export default {
  name: "ForIndividualsPage",
};
</script>
