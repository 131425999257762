<template>
<!--Title-->
  <div>
    <InformationModal ref="informationModal"/>
    <div v-for="(factor, index) in calculated_scores">
      <div class="mt-6 font-bold text-gray-700 text-base text-left">

      </div>
<!--Accordion-->
      <div class="card-bg-white-padding">
        <Disclosure v-slot="{ open }">
          <div class="flex-1 space-y-1">
            <div class="cursor-pointer" @click="openModal(factor.name, factor.factor_description)">
              <div class="flex items-center justify-between mb-4">
                <div class="flex flex-row items-center justify-between w-full mt-2 py-px text-display-base">
                  <p class="flex">
                    {{ factor.name }}
                    <span class="ml-4 cursor-pointer" @click="openModal(factor.name, factor.factor_description)">
                      <InformationCircleIcon class="h-6 w-auto text-gray-300" />
                    </span>
                    <span v-if="factor.diff" :class="[isBadDirection(factor) ? 'text-red-600' : 'text-green-600', 'ml-4 flex items-center']">
                      <component :is="factor.positive ? ArrowSmallUpIcon : ArrowSmallDownIcon" class="stroke-2 h-4 w-4" />
                    </span>
                    <span v-if="factor.diff" :class="[isBadDirection(factor) ? 'text-red-600' : 'text-green-600','font-semibold']">
                      {{ factor.diff }}%
                    </span>
                  </p>
                  <span class="flex flex-row">
                    <div class="text-display-base">
                      {{ getLastValue(factor.scores) }}%
                    </div>
                  </span>
                </div>
              </div>
              <div class="w-full h-2 bg-black/10 rounded-md hover:bg-gray-300">
                <div class="h-2 rounded-md" :style="{'width': (getLastValue(factor.scores) || 0) + '%', 'background-color': getFactorColor(factor)}"></div>
              </div>
            </div>

            <div class="pt-4 text-gray-600 font-normal text-base">{{ factor.category }}</div>
            <div class="pt-2 text-gray-600 font-normal text-base">{{ factor.personal_description }}</div>
        
            <DisclosureButton v-if="factor.scores.length > 1" class="flex w-full items-center justify-between rounded-lg">
              <div class="pt-4">
                <div :class="open ? '' : ''" class="text-left font-bold text-sm" :style="{'color': getFactorColor(factor)}">
                  SEE PREVIOUS SCORES
                </div>
              </div>
            </DisclosureButton>
          </div>

          <transition
              enter-active-class="transition duration-100 ease-out"
              enter-from-class="transform scale-95 opacity-0"
              enter-to-class="transform scale-100 opacity-100"
              leave-active-class="transition duration-75 ease-out"
              leave-from-class="transform scale-100 opacity-100"
              leave-to-class="transform scale-95 opacity-0">
            <DisclosurePanel class="p-2 leading-normal text-base font-sans font-normal text-white">
<!--Content-->
              <div class="mt-8 mb-4">
              <line-chart class=""
                  id="" width="250px" height="200px"
                  :colors="[getFactorColor(factor), '#666']"
                  :data="getPreviousData(factor.scores)"
                  :library="{scales: {y: {min: 0, max: 100}}}"
                >
              </line-chart>
            </div>
            </DisclosurePanel>
          </transition>
        </Disclosure>
      </div>
    </div>
  </div>
</template>

<script setup>

import { Popover, PopoverButton, PopoverOverlay, PopoverPanel } from '@headlessui/vue'
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
import { ChevronRightIcon, SunIcon, InformationCircleIcon } from '@heroicons/vue/20/solid'
import { ChevronUpIcon } from '@heroicons/vue/20/solid'
import InformationModal from './InformationModal';
import { generateColorCode } from './entities/functions.js';
import { ArrowSmallUpIcon, ArrowSmallDownIcon } from '@heroicons/vue/24/outline'

</script>

<script>
export default {
  props: {
    data: Object
  },
  data: function () {
    return {
      title: '',
      calculated_scores: [],
      informationModalActions: {
        closeModal: null,
        openModal: null
      },
    }
  },

  provide: function() {
    return {
      informationModalActions: this.informationModalActions
    };
  },

  computed: {
    
  },

  mounted() {
    this.title = this.data?.title || '';
    this.calculated_scores = this.data?.calculated_scores || [];
  },

  methods: {
    getLastValue(array) {
      if (array && array.length) {
        return array[array.length - 1].value;
      }
      return null;
    },

    getPreviousData(array) {
      let data = [];
      array.forEach(element => {
        data.push([element.created_at, element.value])
      });
      return data;
    },

    getFactorColor(factor) {
      if (factor.color) {
        return factor.color;
      }
      return generateColorCode(factor.name);
    },

    openModal(title, description_one, description_two) {
      let text = [];
      if (description_one) {
        text.push(description_one);
      }
      if (description_two) {
        text.push(description_two);
      }
      if (text.length) {
        this.informationModalActions.openModal(title, text);
      }
    },

    closeModal() {
      this.informationModalActions.openModal();
    },

    isBadDirection(factor) {
      let state = factor.positive;
      if (factor.is_increase_positive) {
        state = !state;
      }
      return state;
    }
  }
}
</script>
