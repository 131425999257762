<template>
  <!-- Header -->
  <header class="header-container-client-view">
    <router-link to="/clients-panel">
      <div class="flex items-center">
        <ChevronLeftIcon class="flex items-center chevron-header-dark md:ml-2" />
        <h1 class="text-page-title-dark">{{ client?.name }}</h1>
      </div>
    </router-link>
  </header>
  <!-- END Header -->
  <loading-overlay :loading="loading"></loading-overlay>
  <div v-if="!loading" class="my-4 md:my-24 overflow-x-hidden">

    <!-- Psychological Health  -->
    <div v-for="collection in collections" :key="collection.id" class="mx-4 border p-4 mt-20 shadow-md rounded-md max-w-6xl">
      <Disclosure>
        <template #default="{ open }">
          <DisclosureButton class="w-full text-left font-semibold">
            <h3>{{ collection.title }}</h3>
          </DisclosureButton>
          <DisclosurePanel>
            <div class="mt-4">
              <psychological-health-factor-history
                v-for="chart in collection.questionnaires"
                :key="chart.id"
                :data="chart"
                data-aos="slide-left"
                data-aos-once="true"
                data-aos-duration="250"
              />
            </div>
          </DisclosurePanel>
        </template>
      </Disclosure>
    </div>

    <!-- Deep Dive -->
    <div class="m-4 p-4 shadow-md border rounded-md max-w-6xl">
    <Disclosure>
      <template #default="{ open }">
        <DisclosureButton class="w-full text-left font-semibold">
          <h3>Deep Dive</h3>
        </DisclosureButton>
        <DisclosurePanel>
          <div class="mt-4">
            <div v-if="Object.keys(deepDive).length">
              <deep-dive-values
                v-for="deepDiveValue in deepDive"
                :key="deepDiveValue.id"
                :data="deepDiveValue"
              />
            </div>
            <div v-else class="card-no-entries">
              <p>There are currently no entries.</p>
            </div>
          </div>
        </DisclosurePanel>
      </template>
    </Disclosure>
  </div>

    <!-- Spotlights -->
    <div class="m-4 p-4 shadow-md border rounded-md max-w-6xl">
    <Disclosure>
      <template #default="{ open }">
        <DisclosureButton class="w-full text-left font-semibold">
          <h3>Spotlights</h3>
        </DisclosureButton>
        <DisclosurePanel>
          <div class="mt-4">
            <div class="flex justify-between">
              <div class="mb-2 flex items-center text-page-subtitle-dark">To Do</div>
            </div>
            <div v-if="spotlightsTodo.length" class="grid md:grid-cols-2">
              <spotlight-card :spotlights="spotlightsTodo" :is-interactive="false" />
            </div>
            <p v-else class="card-no-entries">
              There are currently no 'Spotlights' in ToDo list.
            </p>

            <div v-if="spotlightsArchive.length">
              <div class="flex justify-between mt-12">
                <div class="mb-2 flex items-center text-page-subtitle-dark">Archive</div>
              </div>
              <div class="grid md:grid-cols-2">
                <spotlight-card
                  :spotlights="spotlightsArchive"
                  :is-interactive="false"
                  :is-show-answer="true"
                />
              </div>
            </div>
          </div>
        </DisclosurePanel>
      </template>
    </Disclosure>
  </div>

    <!-- Activities -->
    <div class="m-4 p-4 shadow-md border rounded-md max-w-6xl">
    <Disclosure>
      <template #default="{ open }">
        <DisclosureButton class="w-full text-left font-semibold">
          <h3>Activities</h3>
        </DisclosureButton>
        <DisclosurePanel>
          <div class="mt-4">
            <!-- To Do Section -->
            <div class="mb-2 flex justify-between">
              <div class="flex items-center text-page-subtitle-dark">To Do</div>
              <div v-if="totalTodoRecords || activitiesTodoFilters.length !== activityTypes.length" class="flex relative">
                <adjustments-vertical-icon class="filter" @click="toggleMenu('todo')" />
                <div v-show="showToDoMenu"
                  class="absolute right-3 top-4 mt-2 w-48 py-2 bg-white rounded-lg rounded-tr-none shadow-xl z-10">
                  <div v-for="todoType in activityTypes" :key="todoType"
                    class="block px-4 py-2 text-gray-800 hover:bg-primary hover:text-white">
                    {{ capitalizeFirstLetter(todoType) }}
                    <input type="checkbox" :value="todoType" v-model="activitiesTodoFilters"
                      class="mt-1 text-primary float-right focus:ring-transparent" />
                  </div>
                </div>
              </div>
            </div>
            <activity-card v-if="totalTodoRecords" :activities="activitiesTodo" :is-interactive="false"
              class="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-3 gap-8" />
            <div v-else class="card-no-entries">
              <div v-if="activitiesTodoFilters.length == activityTypes.length">
                <p>There are currently no 'Activities' in ToDo list.</p>
              </div>
              <div v-else>
                <p class="py-8 px-12 md:px-8 text-center text-body-base">
                  Oops! It seems your search didn't match any 'Activities' in ToDo list.
                </p>
              </div>
            </div>

            <!-- Archive Section -->
            <div class="mt-12 flex justify-between mb-2">
              <div class="flex items-center text-page-subtitle-dark">Archive</div>
              <div v-if="totalArchiveRecords || activitiesArchiveFilters.length !== activityTypes.length"
                class="flex relative">
                <adjustments-vertical-icon class="filter" @click="toggleMenu('archive')" />
                <div v-show="showArchiveMenu"
                  class="absolute right-3 top-4 mt-2 w-48 py-2 bg-white rounded-lg rounded-tr-none shadow-xl z-10">
                  <div v-for="archiveType in activityTypes" :key="archiveType"
                    class="block px-4 py-2 text-gray-800 hover:bg-primary hover:text-white">
                    {{ capitalizeFirstLetter(archiveType) }}
                    <input type="checkbox" :value="archiveType" v-model="activitiesArchiveFilters"
                      class="mt-1 text-primary float-right focus:ring-transparent" />
                  </div>
                </div>
              </div>
            </div>
            <activity-card v-if="totalArchiveRecords" :activities="activitiesArchive" :is-interactive="false"
              class="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-3 gap-8" />
            <div v-else class="card-no-entries">
              <div v-if="activitiesArchiveFilters.length == activityTypes.length">
                <p>There are currently no 'Activities' in Archive.</p>
              </div>
              <div v-else>
                <p class="py-8 px-12 md:px-8 text-center text-body-base">
                  Oops! It seems your search didn't match any 'Activities' in Archive.
                </p>
              </div>
            </div>
          </div>
        </DisclosurePanel>
      </template>
    </Disclosure>
  </div>
    <!-- Case Formulation -->
    <div class="m-4 p-4 border shadow-md rounded-md max-w-6xl">
    <Disclosure>
      <template #default="{ open }">
        <DisclosureButton class="w-full text-left font-semibold">
          <h3>Case Formulation</h3>
        </DisclosureButton>
        <DisclosurePanel>
          <main class="mt-4" data-aos="slide-left" data-aos-once="true" data-aos-duration="250">
            <div class="max-w-lg">
              <!-- Tab Headings -->
              <TabGroup>
                <TabList class="flex rounded-lg card-shadow bg-primary-600">
                  <Tab v-slot="{ selected }" as="template" class="my-2 mx-2 rounded-lg text-white">
                    <button :class="[
                      'text-lg w-full font-normal leading-5 text-black',
                      'ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-none',
                      selected ? 'bg-primary' : 'hover:bg-primary hover:text-white',
                    ]">
                      What Does it <br />Look Like?
                    </button>
                  </Tab>
                  <Tab v-slot="{ selected }" as="template" class="my-2 mx-2 py-2 rounded-lg text-white">
                    <button :class="[
                      'text-lg w-full font-normal leading-5',
                      'ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-none',
                      selected ? 'bg-primary' : 'hover:bg-primary hover:text-white',
                    ]">
                      How Did I <br />Get Here?
                    </button>
                  </Tab>
                </TabList>

                <TabPanels>
                  <!-- CF - Part 1 -->
                  <TabPanel data-aos="slide-left" data-aos-once="true" data-aos-duration="250">
                    <div class="mt-8">
                      <case-formulation-symptoms :symptoms="client?.symptom || []" :on-client-page="true" />
                    </div>
                    <div class="mt-4">
                      <case-formulation-triggers :problems="client?.problem || []" :on-client-page="true" />
                    </div>
                    <!-- Triad -->
                    <div class="mt-4 md:max-w-lg">
                      <div class="flex justify-center">
                        <div>
                          <case-formulation-feelings :feelings="client?.feeling || []" :on-client-page="true" />
                        </div>
                      </div>
                      <div class="mt-4 flex items-center justify-between">
                        <div class="md:ml-16">
                          <case-formulation-thoughts :thoughts="client?.thought || []" :on-client-page="true" />
                        </div>
                        <div class="items-center">
                          <img class="h-28 w-auto" src="/img/webapp/case_formulation/brain.png" alt="Human brain" />
                        </div>
                        <div class="md:mr-16">
                          <case-formulation-behaviours :behaviours="client?.behaviour || []" :on-client-page="true" />
                        </div>
                      </div>
                    </div>
                    <div class="mt-4">
                      <case-formulation-life-balance :lifeslices="client?.lifeslice || []" :on-client-page="true" />
                    </div>
                  </TabPanel>

                  <!-- CF - Part 2 -->
                  <TabPanel data-aos="slide-left" data-aos-once="true" data-aos-duration="250">
                    <div class="mt-8">
                      <case-formulation-life-traps :lifetraps="client?.lifetrap || []" :on-client-page="true" />
                    </div>
                    <div class="mt-4">
                      <case-formulation-states :emotionalstates="client?.emotionalstate || []" :on-client-page="true" />
                    </div>
                    <div class="mt-4">
                      <case-formulation-parenting :parentstyles="client?.parentstyle || []" :on-client-page="true" />
                    </div>
                    <div class="mt-4">
                      <case-formulation-values :values="client?.value || []" :on-client-page="true" />
                    </div>
                    <div class="mt-4">
                      <case-formulation-personality :characters="client?.character || []" :on-client-page="true" />
                    </div>
                  </TabPanel>
                </TabPanels>
              </TabGroup>
            </div>
          </main>
        </DisclosurePanel>
      </template>
    </Disclosure>
  </div>
    <!-- Treatment Plan -->
    <div class="m-4 p-4 border shadow-md rounded-md max-w-6xl">
    <Disclosure>
      <template #default="{ open }">
        <DisclosureButton class="w-full text-left font-semibold">
          <h3>Treatment Plan</h3>
        </DisclosureButton>
        <DisclosurePanel>
          <main class="mt-4" data-aos="slide-left" data-aos-once="true" data-aos-duration="250">
            <div class="max-w-lg">
              <!--  Stages Heading -->
              <TabGroup>
                <TabList class="flex rounded-lg card-shadow bg-primary-600">
                  <Tab v-slot="{ selected }" as="template" class="my-2 mx-2 py-2 rounded-lg text-white">
                    <button :class="[
                      'text-lg w-full font-normal leading-5 text-black',
                      'ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-none',
                      selected ? 'bg-primary' : 'hover:bg-primary hover:text-white',
                    ]">
                      What Do We Want <br />it to Look Like?
                    </button>
                  </Tab>
                  <Tab v-slot="{ selected }" as="template" class="my-2 mx-2 py-2 rounded-lg text-white">
                    <button :class="[
                      'text-lg w-full font-normal leading-5',
                      'ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-none',
                      selected ? 'bg-primary' : 'hover:bg-primary hover:text-white',
                    ]">
                      How Will We <br />Get There?
                    </button>
                  </Tab>
                </TabList>

                <TabPanels>
                  <!-- Part 1 -->
                  <TabPanel data-aos="slide-left" data-aos-once="true" data-aos-duration="250">
                    <div class="mt-8">
                      <treatment-plan-aims :aims="client?.aim || []" :on-client-page="true" />
                    </div>
                    <div class="pt-4">
                      <treatment-plan-goals :goals="client?.goal || []" :on-client-page="true" />
                    </div>
                  </TabPanel>

                  <!-- Part 2 -->
                  <TabPanel data-aos="slide-left" data-aos-once="true" data-aos-duration="250">
                    <div class="mt-8">
                      <treatment-plan-actions :actions="client?.goalaction || []" :goals="client?.goal || []" :on-client-page="true" />
                    </div>
                  </TabPanel>
                </TabPanels>
              </TabGroup>
            </div>
          </main>
        </DisclosurePanel>
      </template>
    </Disclosure>
  </div>
    <!-- Notes -->
    <div class="p-4 m-4 border max-w-6xl shadow-md rounded-md">
    <Disclosure>
      <template #default="{ open }">
        <DisclosureButton class="w-full text-left font-semibold">
          <h3>Notes</h3>
        </DisclosureButton>
        <DisclosurePanel>
          <div v-for="note in reversedNotes" :key="note.id" class="border p-4 my-2 shadow-md rounded-md max-w-lg">
            <Disclosure>
              <template #default="{ open }">
                <DisclosureButton class="w-full text-left">
                  <h3 class="font-semibold">{{ note.title }}</h3>
                </DisclosureButton>
                <DisclosurePanel>
                  <hr class="mb-2">
                  <p v-html="note.description"></p>
                </DisclosurePanel>
              </template>
            </Disclosure>
          </div>
        </DisclosurePanel>
      </template>
    </Disclosure>
  </div>

  </div>
</template>

<script setup>
  import { ref } from 'vue'
  import { AdjustmentsVerticalIcon, ChevronLeftIcon } from '@heroicons/vue/24/outline';
  import { sendPost } from '../components/entities/functions.js';
  import LoadingOverlay from '../components/LoadingOverlay';
  import PsychologicalHealthFactorHistory from '../components/PsychologicalHealthFactorHistory';
  import DeepDiveValues from '../components/DeepDiveValues';
  import SpotlightCard from '../components/SpotlightCard';
  import ActivityCard from '../components/ActivityCard';
  import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'

  import { TabGroup, TabList, Tab, TabPanels, TabPanel } from '@headlessui/vue'

  import CaseFormulationSymptoms from '../components/CaseFormulationSymptoms';
  import CaseFormulationTriggers from '../components/CaseFormulationTriggers';
  import CaseFormulationBehaviours from '../components/CaseFormulationBehaviours';
  import CaseFormulationThoughts from '../components/CaseFormulationThoughts';
  import CaseFormulationFeelings from '../components/CaseFormulationFeelings';
  import CaseFormulationPersonality from '../components/CaseFormulationPersonality';
  import CaseFormulationValues from '../components/CaseFormulationValues';
  import CaseFormulationLifeBalance from '../components/CaseFormulationLifeBalance';
  import CaseFormulationLifeTraps from '../components/CaseFormulationLifeTraps';
  import CaseFormulationStates from '../components/CaseFormulationStates';
  import CaseFormulationParenting from '../components/CaseFormulationParenting';

  import TreatmentPlanAims from '../components/TreatmentPlanAims';
  import TreatmentPlanGoals from '../components/TreatmentPlanGoals';
  import TreatmentPlanActions from '../components/TreatmentPlanActions';

</script>

<script>
  export default {
    data: function () {
      return {
        client: {},
        loading: false,
        collections: {},
        deepDive: {},
        spotlightsTodo: {},
        spotlightsArchive: {},
        activitiesTodo: [],
        activitiesArchive: [],
        activityTypes: ['questionnaires', 'worksheets', 'readings'],
        activitiesTodoFilters: ['questionnaires', 'worksheets', 'readings'],
        activitiesArchiveFilters: ['questionnaires', 'worksheets', 'readings'],
        showToDoMenu: false,
        showArchiveMenu: false,
        note: [], // Initialize as an empty array
      }
    },

    computed: {
      client_id() {
        return this.$route.params.clientId
      },
      totalTodoRecords() {
        let total = 0;
        if (this.activitiesTodo) {
          if (this.activitiesTodo.questionnaire_instances) {
            total += this.activitiesTodo.questionnaire_instances.length;
          }
          if (this.activitiesTodo.reading_instances) {
            total += this.activitiesTodo.reading_instances.length;
          }
          if (this.activitiesTodo.worksheet_instances) {
            total += this.activitiesTodo.worksheet_instances.length;
          }
        }
        return total;
      },
      totalArchiveRecords() {
        let total = 0;
        if (this.activitiesArchive) {
          if (this.activitiesArchive.questionnaire_instances) {
            total += this.activitiesArchive.questionnaire_instances.length;
          }
          if (this.activitiesArchive.reading_instances) {
            total += this.activitiesArchive.reading_instances.length;
          }
          if (this.activitiesArchive.worksheet_instances) {
            total += this.activitiesArchive.worksheet_instances.length;
          }
        }
        return total;
      },
      reversedNotes() {
      // Return a reversed copy of the notes array
      return this.client.note ? this.client.note.slice().reverse() : [];
    }
    },

    watch: {
      activitiesTodoFilters() {
        this.getClientInfo();
      },
      activitiesArchiveFilters() {
        this.getClientInfo();
      },
    },

    mounted() {
      this.getClientInfo();
    },

    methods: {
      async getClientInfo() {
        this.loading = true;
        const responce = await sendPost('/api/get/client-info', {
            client_id: this.client_id,
            activities_todo_types: this.activitiesTodoFilters,
            activities_archive_types: this.activitiesArchiveFilters
          });
          if (responce) {
            this.client = responce.client;
            this.collections = responce.collections;
            this.deepDive = responce.deep_dive;
            this.spotlightsTodo = responce.spotlights?.todo;
            this.spotlightsArchive = responce.spotlights?.archive;
            this.activitiesTodo = responce.activities?.todo;
            this.activitiesArchive = responce.activities?.archive;
          }
          this.loading = false;
      },

      toggleMenu(type) {
        if (type == 'todo') {
          this.showToDoMenu = !this.showToDoMenu;
        } else {
          this.showArchiveMenu = !this.showArchiveMenu;
        }
      },

      capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
      }
    }
  }
</script>
