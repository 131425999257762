<template>
  <div class="schools-universities-page">
    
<!-- SECTION-Hero -->
    <BlockHero
      headline="Enhance Mental Well-being in Schools and Universities"
      subheadline=""
      bgClass="bg-gray-800"
      theme="dark"
    />

<!-- SESSION-Introduction -->
    <BlockContent
      overline="Empowering Education"
      headline="Why Choose Youlio for Educational Institutions"
      subheadline="Educational environments come with unique mental health challenges. Youlio is designed to address these needs with comprehensive, accessible support tailored specifically for schools and universities. From one-off sessions to ongoing support programs, I use Youlio to help create a supportive, proactive approach to mental well-being."
      bgClass="bg-white"
      theme="light"
      layout="image-right"
      imageSrc="/img/website/schools-why-choose-youlio.jpg"
    />

    
<!-- SECTION-Key Features -->
      <Wave colorClass="fill-white" shadow position="down" /> 
      <BlockKeyFeatures
            title="Key Features for Schools and Universities"
            :features="[
              { title: 'Tailored Programs', description: 'Customisable mental health programs that address the specific needs of students, faculty, and staff.', icon: KeyIcon },
              { title: 'Accessible Support', description: '24/7 access to resources and support, ensuring help is available whenever it\'s needed.', icon: BoltIcon },
              { title: 'Flexible Scheduling', description: 'Easily schedule and manage appointments, workshops, and support sessions.', icon: CalendarIcon },
              { title: 'Confidential and Secure', description: 'Ensuring that all interactions and data are secure and confidential.', icon: LockClosedIcon }
            ]"
          />

<!-- SECTION-My Commitment to Educational Institutions -->
      <Wave colorClass="fill-white" shadow position="down" /> 
      <BlockContent
        overline="Elevating Education"
        headline="My Commitment to Educational Institutions"
        subheadline="Understanding the unique pressures faced by students and staff, I am dedicated to offering tailored mental health support through Youlio. My focus is on providing effective, personalised solutions that align with the values and needs of educational institutions."
        bgClass="bg-white"
        theme="light"
        layout="image-right"
        imageSrc="/img/website/me-4.jpg"
      />
 
<!-- SECTION-Example Programs -->
      <Wave colorClass="fill-white" shadow position="down" /> 
      <BlockSwiper
        :slides="3"
        overline="Example Programs"
        headline="Supporting Student and Staff Wellbeing"
        subheadline="Discover programs designed to tackle common mental health challenges in educational settings, tailored to your institution's specific needs."
>
        <template #slide-0>
          <BlockContentInSwiperProgram
        headline="Boosting Your Self-esteem"
        subheadline="Develop a stronger sense of self-worth and confidence to enhance your academic and social experiences. This program provides practical strategies for overcoming self-doubt and building a positive self-image."
        imageSrc="/img/website/program-self-esteem-2.jpg"
        bgClass="bg-white"
        theme="light"
        layout="image-right"
        paddingTopClass="pt-4 sm:pt-4 md:pt-4"
        paddingBottomClass="pb-4 sm:pb-4 md:pb-4"
        />
        </template>
        <template #slide-1>
          <BlockContentInSwiperProgram
        headline="Managing Exam Anxiety"
        subheadline="Learn effective techniques to manage and reduce anxiety related to exams and academic pressure. This program offers tools for relaxation, stress management, and maintaining focus to help you perform at your best."
        imageSrc="/img/website/program-exam-anxiety.jpg"
        bgClass="bg-white"
        theme="light"
        layout="image-right"
        paddingTopClass="pt-4 sm:pt-4 md:pt-4"
        paddingBottomClass="pb-4 sm:pb-4 md:pb-4"
        />
        </template>
        <template #slide-2>
          <BlockContentInSwiperProgram
        headline="Optimising Your Mental Health"
        subheadline="Enhance your overall well-being with tailored strategies for balance and resilience. This program focuses on practical tools and techniques to optimise your mental health for a more fulfilling life."
        imageSrc="/img/website/program-mental-health-2.jpg"
        bgClass="bg-white"
        theme="light"
        layout="image-right"
        paddingTopClass="pt-4 sm:pt-4 md:pt-4"
        paddingBottomClass="pb-4 sm:pb-4 md:pb-4"
        />
        </template>
       
      </BlockSwiper>

 
   
<!-- SECTION-CTA -->
    <Wave colorClass="fill-white" shadow position="down" /> 
    <BlockCTA    
      heading="Ready to Improve Mental Health in Your Institution?"
      message="Contact me today to learn more about how Youlio can make a positive impact on your educational community."
      primaryButtonText="Get in Touch"
      primaryButtonLink="mailto:tom@youliohealth.com?subject=I'm%20Interested%20in%20Your%20Work%20with%20Schools%20and%20Universities"
      imageSrc="/img/website/schools-cta.jpg"
      bgClass="bg-white"
      theme="light"
    />

  </div>
</template>

<script>
export default {
  name: "SchoolsUniversitiesPage",
};
</script>

<script setup>
      
import BlockCTA from '../../website/components/BlockCTA' ;
import Wave from '../../website/components/Wave' ;
import BlockHero from '../../website/components/BlockHero' ;
import BlockKeyFeatures from '../../website/components/BlockKeyFeatures' ;
import BlockSwiper from '../../website/components/BlockSwiper' ;
import BlockContentInSwiperProgram from '../../website/components/BlockContentInSwiperProgram' ;
import BlockContent from '../../website/components/BlockContent' ;
import BlockStructureTitle from '../../website/components/BlockStructureTitle' ;
import { KeyIcon, BoltIcon, CalendarIcon, LockClosedIcon } from '@heroicons/vue/24/outline'
import { ChevronRightIcon } from '@heroicons/vue/20/solid'   

</script>
