<template>

<!-- SECTION-Hero -->
<img class="" src="img/website/wave-white-1.jpg" alt="Youlio logo" />

    <section class="relative bg-white">
        <div class="mx-auto max-w-7xl lg:grid lg:grid-cols-12 lg:gap-x-8 lg:px-8">
            <div class="px-6 pb-4 pt-8 sm:pb-20 lg:col-span-7 lg:px-0 lg:pb-56 lg:pt-24 xl:col-span-6">
                <div class="mx-auto max-w-2xl lg:mx-0">
                    <h1 class="text-center sm:text-left mt-4 sm:mt-24 text-4xl font-bold  text-gray-900 sm:mt-10 sm:text-5xl">Personalised Therapy and Mental Health Support</h1>
                    <p class="text-center sm:text-left mt-4 text-lg text-gray-600 sm:text-xl">Tailored 1:1 Care and Expert Guidance to Help You Thrive in Every Aspect of Life, Every Step of the Way</p>
                    <div class="mt-8 sm:mt-10 flex flex-col space-y-4 sm:flex-row sm:space-y-0 sm:space-x-4">
                        <!-- Button1 -->
                        <a href="/individuals" class="w-64 mx-auto sm:mx-0 flex items-center justify-center rounded-lg bg-primary px-0 py-3 text-lg font-medium tracking-wide text-white shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600">
                            <span>For Individuals</span>
                            <svg class="text-primary-100 h-6 w-auto ml-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path></svg>
                        </a>
                        <!-- Button2 -->
                        <a href="/organisations" class="w-64 mx-auto sm:mx-0 flex items-center justify-center rounded-lg bg-secondary-600 px-0 py-3 text-lg font-medium tracking-wide text-white shadow-sm hover:bg-secondary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600">
                            <span>For Organisations</span>
                            <svg class="text-secondary-100 h-6 w-auto ml-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path></svg>
                        </a>
                    </div>
                </div>
            </div>
            <div class="relative lg:col-span-5 lg:-mr-8 xl:absolute xl:inset-0 xl:left-1/2 xl:mr-0">
                <div class="relative w-full h-0" style="padding-bottom:100%;">
                    <img class="sm:p-16 absolute inset-0 w-full h-full object-cover object-center" src="/img/website/landing-hero.jpg" alt="" />
                </div>
            </div>
        </div>
    </section>
  
<!-- SECTION-Introduction Video -->


<img class="transform -scale-y-100" src="img/website/wave-white-1.jpg" alt="Youlio logo" />

        <section class="px-8 sm:px-6 max-w-4xl mx-auto pt-4 pb-12 sm:py-20">
        <TypingEffect class="py-8" />
        <video class="mb-8 mx-auto sm:max-w-md rounded-xl shadow-lg" controls playsinline preload="metadata" 
          :poster="shouldUsePoster ? '/img/website/video-poster-me-1.jpg' : null">
            <source src="/img/website/landing-video-1.mp4" type="video/mp4">
            Your browser does not support the video tag.
          </video>
          <div class="max-w-3xl mx-auto border-2 border-primary-600 border-opacity-20 rounded-2xl p-4 sm:p-6">
          <p class=" text-left ws-text-body-lg text-gray-600">
          "I created the Youlio platform to provide simple and effective mental health support. With a background in therapy and a passion for helping people, I aim to make mental health care accessible and tailored to your unique needs." 
        </p>  
        <p class="mt-2 text-left text-xs font-semibold text-primary ">(Tom Mitchell, MSc PGDip MBACP GMBPsS)
        </p>
      </div>
        </section>
    
<!-- SECTION-For Individuals -->
<img class="" src="img/website/wave-white-1.jpg" alt="Youlio logo" />
    <BlockContent
    overline="For Individuals"
    headline="Your Journey to Better Mental Health Starts Here"
    subheadline="Discover personalised mental health support tailored to address your unique challenges and goals. Whether you're seeking therapy, a one-time MOT, or structured programs, I offer a range of options through Youlio to support your journey."
    secondaryCtaText="Learn More"
    secondaryCtaLink="/individuals"
    imageSrc="/img/website/landing-for-individuals.jpg"
    bgClass="bg-white"
    theme="light"
    layout="image-right" 
    paddingTopClass="pt-8 sm:pt-20 md:pt-24"
      paddingBottomClass="pb-16 sm:pb-20 md:pb-24"
    />

<!-- SECTION-For Organisations -->
    <BlockContent
    overline="For Organisations"
    headline="Creating Healthier Spaces for Learning and Growth"
    subheadline="Enhance the mental health and well-being of your organisation with tailored solutions. From 1:1 therapy, workshops, to ongoing programs, through Youlio I provide comprehensive support to help your organisation thrive."
    secondaryCtaText="Learn More"
    secondaryCtaLink="/organisations"
    imageSrc="/img/website/landing-for-organisations.jpg"
    bgClass="bg-secondary-100"
    theme="light"
    layout="image-right" 
    />


    <img class="" src="img/website/wave-light-blue-white-1.jpg" alt="Youlio logo" />
<!-- SECTION-How Youlio Supports Your Journey -->
      <BlockSwiper
        :slides="4"
        overline="Your Private, Safe Space"
        headline="Enhancing Your Mental Health Journey"
        subheadline="Bringing your therapy journey into one convenient place allows us to address your needs in a structured, tailored manner, personalised to your unique requirements."
>
        <template #slide-0>
          <BlockContentInSwiper
        headline="Smooth Sailing Sessions"
        subheadline="Youlio makes therapy sessions a breeze! The platform handles all of our sessions, making it super easy for us to connect."
        imageSrc="/img/website/landing-features-1.jpg"
        bgClass="bg-white"
        theme="light"
        layout="image-right"
        paddingTopClass="pt-4 sm:pt-4 md:pt-4"
        paddingBottomClass="pb-8 sm:pb-8 md:pb-8"
        />
        </template>
        <template #slide-1>
          <BlockContentInSwiper
        headline="Messaging"
        subheadline="You can reach out to me anytime with our messaging feature, so you're never alone on your journey."
        imageSrc="/img/website/landing-features-2.jpg"
        bgClass="bg-white"
        theme="light"
        layout="image-right"
        paddingTopClass="pt-4 sm:pt-4 md:pt-4"
        paddingBottomClass="pb-8 sm:pb-8 md:pb-8"
        />
        </template>
        <template #slide-2>
          <BlockContentInSwiper
        headline="Personalised Support"
        subheadline="With Youlio, I can send you readings, questionnaires, and activities that are completely tailored towards you."
        imageSrc="/img/website/landing-features-3.jpg"
        bgClass="bg-white"
        theme="light"
        layout="image-right"
        paddingTopClass="pt-4 sm:pt-4 md:pt-4"
        paddingBottomClass="pb-8 sm:pb-8 md:pb-8"
        />
        </template>
        <template #slide-3>
          <BlockContentInSwiper  
        headline="Progress Tracking"
        subheadline="Youlio enables us to monitor your journey throughout treatment, keeping us focused on the areas that matter most."
        imageSrc="/img/website/landing-features-4.jpg"
        bgClass="bg-white"
        theme="light"
        layout="image-right"
        paddingTopClass="pt-4 sm:pt-4 md:pt-4"
        paddingBottomClass="pb-8 sm:pb-8 md:pb-8"
        />
        </template>
      </BlockSwiper>
   
<!-- SECTION-Testimonials-->
      <BlockTestimonial
  :testimonials="[
    {
      text: 'Starting therapy with Tom has been a crucial part of my mental health journey. His unwavering support has empowered me to make positive changes in my life. Thank you, Tom!',
      name: 'Amanda',
      age: 31
    },
    {
    text: 'Tom’s program helped me create lasting changes in my mental wellbeing. The practical strategies were so valuable to me. Highly recommended!',
    name: 'Emily',
    age: 27
  },
  {
      text: 'The mental health MOT was a game-changer for me. Quick, insightful results and it encouraged me to do something about my mental health now and not ignore it. Highly recommend!',
      name: 'Sarah',
      age: 29
    },
  ]"
  sectionBgColor="bg-secondary-600"
      />
 <!-- SECTION-Why Choose Youlio -->
      <BlockWhyChoose
      overline="Personalised Support"
      headline="Why Youlio Stands Out"
      subheadline="Combining my therapeutic expertise with Youlio's innovative platform creates a simple, highly effective therapy experience tailored specifically to your needs."
      listImageSrc="/img/website/landing-why-choose-youlio.jpg"
      listImageAlt="Why Choose Us"
      :listItems="[
        { title: 'All-Around Support', description: 'Youlio goes beyond sessions, offering continuous support to keep you moving forward every step of the way.' },
        { title: 'Flexible Options', description: 'Whether it’s therapy, programs, or MOTs, Youlio offers something that fits everyone’s needs.' },
        { title: 'Guided by Experience', description: 'With the structure of Youlio and the guidance of an experienced therapist, you get a uniquely effective and personalised approach.' },
      ]"
      />
<!-- SECTION-CTA -->
<img class="" src="img/website/wave-white-1.jpg" alt="Youlio logo" />
    <BlockCTA    
      heading="Ready to Start Your Journey?"
      message="Get in touch today to explore how Youlio can support your mental health journey with tailored, flexible, and ongoing care."
      primaryButtonText="For Individuals"
      primaryButtonLink="/individuals"
      secondaryButtonText="For Organisations"
      secondaryButtonLink="/organisations"
      imageSrc="/img/website/landing-cta.jpg"
      bgClass="bg-white"
      theme="light"
      paddingTopClass="pt-16 sm:pt-20 md:pt-24"
      paddingBottomClass="pb-16 sm:pb-20 md:pb-24"
    />       
<!--  SECTION-Emergency --> 
    <section>
      <div class="overflow-x-hidden flex items-center justify-start bg-gray-100 px-6 py-2.5 sm:px-8">
        <div class="flex my-4">
        <MegaphoneIcon class="h-6 text-gray-800 shrink-0" aria-hidden="true" />
        <p class="ml-2 text-sm leading-6 text-gray-900">
        If you or someone else is in crisis - don't use this site. <a href="/helplines" class="whitespace-nowrap font-semibold">THESE RESOURCES</a> can provide you with immediate help.
        </p>
        </div>
      </div>
    </section>

</template>
    
<script setup>
    
      import Banner from '../../website/components/Banner' ;
      import BlockTestimonial from '../../website/components/BlockTestimonial' ;
      import BlockSwiper from '../../website/components/BlockSwiper' ;
      import BlockContentInSwiper from '../../website/components/BlockContentInSwiper' ;
      import Wave from '../../website/components/Wave' ;
      import BlockContent from '../../website/components/BlockContent' ;
      import BlockWhyChoose from '../../website/components/BlockWhyChoose' ;
      import BlockCTA from '../../website/components/BlockCTA' ;
      import TypingEffect from '../../website/components/TypingEffect' ;
      import BlockStructureTitle from '../../website/components/BlockStructureTitle' ;
      import { MegaphoneIcon } from '@heroicons/vue/24/outline'
      import { ChevronRightIcon } from '@heroicons/vue/20/solid'
</script>
    
<script>
    export default {
      data() {
        return {
          shouldUsePoster: true
        };
      }
    }
</script>
    
    