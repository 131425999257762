<template>
 
  <div class="min-h-screen overflow-x-hidden mx-auto">
    <WelcomeModal v-if="!user.welcome_modal_viewed"/>
 
<!-- Dashboard Header -->	
    <header>
      <div class="h-8 md:h-28 bg-white flex items-center justify-center ">
        
      </div>
      <div class=" px-4 md:px-10 border-b border-gray-100 overflow-visible h-10 md:h-10 relative flex items-center rounded-sm bg-white">
        <div class="z-40  inline-block h-12 w-auto overflow-hidden ring-2 ring-white shadow-md rounded-full">
          <img class="h-12" src="/img/avatar.jpg" alt="Therapist face image" />
        </div>
          <span class="absolute inset-0" aria-hidden="true" />

          <div class="ml-2 text-xl font-normal">
  <span class="text-gray-700">
    Hi {{ userName }},
  </span>
</div>
          
        </div>
    </header>
<!-- END Dashboard Header -->	

<main class="px-4 md:px-10 pb-24 md:pb-8 " data-aos="slide-left" data-aos-once="true" data-aos-duration="250">
      <div class="grid grid-cols-1 md:gap-10 md:grid-cols-2 xl:grid-cols-3">
        <div class="md:col-span-1">
          <dashboard-next-session />
          <div class="hidden md:block ">
          <dashboard-spotlights />
        </div>
        </div>
        <div class="md:col-span-1">
          <dashboard-collections />
        </div>
        <div class="md:hidden">
        <dashboard-spotlights />
      </div>
        <div class="md:col-span-1">
          <dashboard-activities />
        </div>
        
      </div>
    </main>

  </div>
</template>

<script setup>

  import DashboardCollections from '../components/DashboardCollections' ;
	import DashboardNextSession from '../components/DashboardNextSession' ;
  import DashboardActivities from '../components/DashboardActivities' ;
  import DashboardSpotlights from '../components/DashboardSpotlights' ;
  import WelcomeModal from '../components/WelcomeModal' ;
	
</script>

<script>
  export default {
    computed: {
      user() {
        return this.$store.getters['user'];
      },
      userName() {
        if (this.user) {
          return this.user.name.split(' ')[0];
        }
        return 'there';
      }
    },
  }
</script>

