<template>
<!--
<banner />
--> 
<div class="top-0 sticky z-40">
    <header :class="{change_color: scrollPosition > 50}" class=" bg-transparent">
    
      <Popover class="relative">
        <div class="border-b-2 border-white/5 flex justify-between items-center max-w-8xl px-4 md:mx-8 py-4 sm:px-6 md:justify-start md:space-x-10 lg:px-4">
<!--Logo-->         
          <div class="flex justify-start lg:w-0 lg:flex-1">
            <a href="/">
              <img class="h-10 w-auto sm:h-11" src="/img/logo-dark.svg" alt="Youlio logo " />
            </a>
          </div>
<!--Burger Menu--> 
          <div class="-mr-2 -my-2 md:hidden">
            <PopoverButton class="bg-transparent rounded-md p-2 inline-flex items-center justify-center text-gray-800 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-none ">
              <Bars3Icon class="h-8 w-auto" aria-hidden="true" />
            </PopoverButton>
          </div>

<!--DESKTOP MENU-->           
    
        <!--Navbar-->      
        <PopoverGroup as="nav" class="hidden md:flex items-center space-x-8">
    <!--For Individuals--> 
      <Popover class="relative" v-slot="{ open }">
          <PopoverButton :class="[open ? 'text-medium' : 'text-white/90', 'group bg-transparent rounded-md inline-flex items-center ws-header-desktop-navigation focus:outline-none focus:ring-none']">
            <span>For Individuals</span>
            <ChevronDownIcon :class="[open ? 'text-gray-800' : 'text-gray-800', 'ml-1 h-5 w-auto group-hover:text-opacity-40']" aria-hidden="true" />
          </PopoverButton>
          <transition enter-active-class="transition ease-out duration-200" enter-from-class="opacity-0 translate-y-1" enter-to-class="opacity-100 translate-y-0" leave-active-class="transition ease-in duration-150" leave-from-class="opacity-100 translate-y-0" leave-to-class="opacity-0 translate-y-1">
            <PopoverPanel class="absolute z-10 left-1/2 transform -translate-x-1/2 mt-3 px-2 w-screen max-w-xs sm:px-0">
              <div class="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">      
                <div class="relative grid gap-4 bg-white p-4">
                  <a v-for="item in individual" :key="item.name" :href="item.href" class="block rounded-md hover:bg-white/5 transition ease-in-out duration-150">
                    <p class="ws-header-desktop-navigation">
                      {{ item.name }}
                    </p>
                  </a>
                </div>
              </div>
            </PopoverPanel>
          </transition>
        </Popover>

      <!--For Organisations--> 
      <Popover class="relative" v-slot="{ open }">
        <PopoverButton :class="[open ? 'text-medium' : 'text-white/90', 'group bg-transparent rounded-md inline-flex items-center ws-header-desktop-navigation focus:outline-none focus:ring-none']">
          <span>For Organisations</span>
          <ChevronDownIcon :class="[open ? 'text-gray-800' : 'text-gray-800', 'ml-1 h-5 w-auto group-hover:text-opacity-40']" aria-hidden="true" />
        </PopoverButton>

        <transition enter-active-class="transition ease-out duration-200" enter-from-class="opacity-0 translate-y-1" enter-to-class="opacity-100 translate-y-0" leave-active-class="transition ease-in duration-150" leave-from-class="opacity-100 translate-y-0" leave-to-class="opacity-0 translate-y-1">
          <PopoverPanel class="absolute z-10 left-1/2 transform -translate-x-1/2 mt-3 px-2 w-screen max-w-xs sm:px-0">
            <div class="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
              <div class="relative grid gap-4 bg-white p-4">
                <a v-for="item in organisation" :key="item.name" :href="item.href" class="block rounded-md hover:bg-white/5 transition ease-in-out duration-150">
                  <p class="ws-header-desktop-navigation">
                    {{ item.name }}
                  </p>
                </a>
              </div>
            </div>
          </PopoverPanel>
        </transition>
      </Popover>

      <!--About--> 
      <Popover class="relative" v-slot="{ open }">
          <PopoverButton :class="[open ? 'text-medium' : 'text-white/90', 'group bg-transparent rounded-md inline-flex items-center ws-header-desktop-navigation focus:outline-none focus:ring-none']">
            <span>About</span>
            <ChevronDownIcon :class="[open ? 'text-gray-800' : 'text-gray-800', 'ml-1 h-5 w-auto group-hover:text-opacity-40']" aria-hidden="true" />
          </PopoverButton>

          <transition enter-active-class="transition ease-out duration-200" enter-from-class="opacity-0 translate-y-1" enter-to-class="opacity-100 translate-y-0" leave-active-class="transition ease-in duration-150" leave-from-class="opacity-100 translate-y-0" leave-to-class="opacity-0 translate-y-1">
            <PopoverPanel class="absolute z-10 left-1/2 transform -translate-x-1/2 mt-3 px-2 w-screen max-w-xs sm:px-0">
              <div class="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">       
                <div class="relative grid gap-4 bg-white p-4">
                  <a v-for="item in about" :key="item.name" :href="item.href" class="block rounded-md hover:bg-white/5 transition ease-in-out duration-150">
                    <p class="ws-header-desktop-navigation">
                      {{ item.name }}
                    </p>
                  </a>
                </div>
              </div>
            </PopoverPanel>
          </transition>
        </Popover>

              <!--Pricing--> 
                <a href="/pricing" class="ws-header-desktop-navigation"> Pricing </a>

                  <!--Resources--> 
                  <Popover class="relative" v-slot="{ open }">
          <PopoverButton :class="[open ? 'text-medium' : 'text-white/90', 'group bg-transparent rounded-md inline-flex items-center ws-header-desktop-navigation focus:outline-none focus:ring-none']">
            <span>Resources</span>
            <ChevronDownIcon :class="[open ? 'text-gray-800' : 'text-gray-800', 'ml-1 h-5 w-auto group-hover:text-opacity-40']" aria-hidden="true" />
          </PopoverButton>

          <transition enter-active-class="transition ease-out duration-200" enter-from-class="opacity-0 translate-y-1" enter-to-class="opacity-100 translate-y-0" leave-active-class="transition ease-in duration-150" leave-from-class="opacity-100 translate-y-0" leave-to-class="opacity-0 translate-y-1">
            <PopoverPanel class="absolute z-10 left-1/2 transform -translate-x-1/2 mt-3 px-2 w-screen max-w-xs sm:px-0">
              <div class="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">       
                <div class="relative grid gap-4 bg-white p-4">
                  <a v-for="item in resources" :key="item.name" :href="item.href" class="block rounded-md hover:bg-white/5 transition ease-in-out duration-150">
                    <p class="ws-header-desktop-navigation">
                      {{ item.name }}
                    </p>
                  </a>
                </div>
              </div>
            </PopoverPanel>
          </transition>
        </Popover>

      <!--Contact--> 
      <a href="/contact" class="ws-header-desktop-navigation"> Contact </a>

                </PopoverGroup>
      <!--Log in and Sign Up--> 
                <div class="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
      <!--Log in--> 
                  <a href="/login" class="ws-header-desktop-navigation whitespace-nowrap "> Login </a>
                  <span><ArrowRightOnRectangleIcon class="ml-1 h-5 w-auto text-gray-800"></ArrowRightOnRectangleIcon> </span>
      <!--Sign up--> 
      <div class="relative inline-block ml-6">
          <button 
            @click="toggleDropdown" 
            ref="dropdownButton" 
            class="bg-gray-700 text-white py-2 px-4 rounded-lg focus:outline-none">
            Get Started
          </button>
          <div 
            id="dropdownMenu" 
            ref="dropdownMenu" 
            class="hidden absolute bg-white shadow-md rounded mt-2 w-48">
            <a href="/individuals/weekly-therapy" class="text-sm font-semibold block px-4 py-2 text-gray-800 hover:bg-secondary-200">Weekly Therapy</a>
            <a href="/individuals/mental-health-programs" class="text-sm font-semibold block px-4 py-2 text-gray-800 hover:bg-secondary-200">Mental Health Programs</a>
            <a href="/individuals/mental-health-mot" class="text-sm font-semibold block px-4 py-2 text-gray-800 hover:bg-secondary-200">My Wellbeing MOT</a>
          </div>
        </div>
                </div>
      <!--End Log in and Sign Up--> 
              </div>
<!--END DESKTOP MENU--> 


<!-- MOBILE MENU -->
<transition 
  enter-active-class="duration-200 ease-out" 
  enter-from-class="opacity-0 scale-95" 
  enter-to-class="opacity-100 scale-100" 
  leave-active-class="duration-100 ease-in" 
  leave-from-class="opacity-100 scale-100" 
  leave-to-class="opacity-0 scale-95"
>

  <PopoverPanel focus class="fixed z-50 top-0 inset-x-0 transition transform origin-top-right md:hidden">
    <div class="h-full overflow-y-auto py-4 bg-white shadow-lg ring-1 ring-black ring-opacity-5">
      
      <!-- Header Section: Close Button -->
      <div class="px-4 flex justify-between mb-4">
        <img class="h-10 w-auto sm:h-11" src="/img/logo-dark.svg" alt="Youlio logo " />
        <PopoverButton class="focus:outline-none">
          <span class="sr-only">Close menu</span>
          <XMarkIcon class="text-gray-800 stroke 2 h-7 w-7" aria-hidden="true" />
        </PopoverButton>
      </div>

      <!-- Menu Section -->
      <nav>
        <div class=" px-8 mt-16 space-y-6">
          
          <!-- For Individuals -->
          <div>
            <button @click="toggleSection('individuals')" class="w-full text-left flex justify-between items-center text-primary-600 font-semibold tracking-wide text-base">
              For Individuals
              <ChevronDownIcon class="stroke-2 text-gray-800 h-6 w-6" :class="{ 'rotate-180': openSection === 'individuals' }"/>
            </button>
            <div v-if="openSection === 'individuals'" class="mt-3 space-y-2">
              <a href="/individuals/weekly-therapy" class="ws-text-menu-mobile block text-gray-600 hover:text-gray-800">Weekly Therapy</a>
              <a href="/individuals/mental-health-programs" class="ws-text-menu-mobile block text-gray-600 hover:text-gray-800">Mental Health Programs</a>
              <a href="/individuals/mental-health-mot" class="ws-text-menu-mobile block text-gray-600 hover:text-gray-800">My Mental Wellbeing MOT</a>
            </div>
          </div>

          <!-- For Organisations -->
          <div>
            <button @click="toggleSection('organisations')" class="w-full text-left flex justify-between items-center text-primary-600 font-semibold tracking-wide text-base">
              For Organisations
              <ChevronDownIcon class="text-gray-800 stroke-2 h-6 w-6" :class="{ 'rotate-180': openSection === 'organisations' }"/>
            </button>
            <div v-if="openSection === 'organisations'" class="mt-3 space-y-2">
              <a href="/organisations/schools-universities" class="ws-text-menu-mobile block text-gray-600 hover:text-gray-800">Schools and Universities</a>
              <a href="/organisations/startups-small-businesses" class="ws-text-menu-mobile block text-gray-600 hover:text-gray-800">Startups and Small Businesses</a>
              <a href="/organisations/charities-nonprofits" class="ws-text-menu-mobile block text-gray-600 hover:text-gray-800">Charities and Non-Profits</a>
            </div>
          </div>

          <!-- About -->
          <div>
            <button @click="toggleSection('about')" class="w-full text-left flex justify-between items-center text-primary-600 font-semibold tracking-wide text-base">
              About
              <ChevronDownIcon class="text-gray-800 stroke-2 h-6 w-6" :class="{ 'rotate-180': openSection === 'about' }"/>
            </button>
            <div v-if="openSection === 'about'" class="mt-3 space-y-2">
              <a href="/approach" class="ws-text-menu-mobile block text-gray-600 hover:text-gray-800">Me and My Approach</a>
              <a href="/platform" class="ws-text-menu-mobile block text-gray-600 hover:text-gray-800">The Youlio Platform</a>
              <a href="/partnerships" class="ws-text-menu-mobile block text-gray-600 hover:text-gray-800">Partner with Youlio</a>
              <a href="/faq" class="ws-text-menu-mobile block text-gray-600 hover:text-gray-800">FAQs</a>
            </div>
          </div>


          <!-- About -->
            <div>         
             <a href="/pricing" class="text-primary-600 font-semibold tracking-wide text-base hover:text-primary-500">Pricing</a>    
            </div>
          
          <!-- Resources -->
          <div>
            <button @click="toggleSection('resources')" class="w-full text-left flex justify-between items-center text-primary-600 font-semibold tracking-wide text-base">
              Resources
              <ChevronDownIcon class="text-gray-800 stroke-2 h-6 w-6" :class="{ 'rotate-180': openSection === 'resources' }"/>
            </button>
            <div v-if="openSection === 'resources'" class="mt-3 space-y-2">
              <a href="/common-problems" class="ws-text-menu-mobile block text-gray-600 hover:text-gray-800">Common Problems</a>
              <a href="/therapy-provides" class="ws-text-menu-mobile block text-gray-600 hover:text-gray-800">What Therapy Can Provide</a>
              <a href="/types-of-therapy" class="ws-text-menu-mobile block text-gray-600 hover:text-gray-800">Types of Therapy</a>
              <a href="/helplines" class="ws-text-menu-mobile block text-gray-600 hover:text-gray-800">Helplines</a>
            </div>
          </div>

           <!-- About -->
           <div>         
            <a href="/contact" class="text-primary-600 font-semibold tracking-wide text-base hover:text-primary-500">Contact</a>  
            </div>


        </div>
      </nav>

      <!-- Call to Action Buttons -->
      <div class="px-8 mt-16 space-y-3">
        <a href="/individuals"> 
          <button class="w-full rounded-lg bg-gray-700 px-4 py-3 text-base text-white shadow-sm hover:bg-gray-700">
            Get Started
          </button>
        </a>
      </div>

      <!-- Login Prompt -->
      <p class="mb-16 mt-3 text-center ws-text-body-sm">
        Already a user? <a href="/login" class="ws-text-body-black-strong-sm">Log in</a>
      </p>
    </div>
  </PopoverPanel>
</transition>


      </Popover>


      
    </header>
  </div>
    
		<router-view />
    
<!-- Footer -->
<footer class="bg-secondary-800" aria-labelledby="footer-heading">
  <div class="mx-auto max-w-7xl py-12 px-4 sm:px-6 lg:pt-24 lg:pb-16 lg:px-8">
    <div class="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-4">
      
      <!-- Contact and Social Media -->
      <div class="space-y-8 lg:col-span-1">
        <p class="font-sans text-base text-white">
          Personalised Therapy<br/>and Mental Health Support
        </p>
        <div class="flex space-x-6">
          <a v-for="item in navigation.social" :key="item.name" :href="item.href" class="text-white hover:text-secondary-300" target="_blank">
            <component :is="item.icon" class="h-8 w-8" aria-hidden="true" />
          </a>
        </div>
      </div>

      <!-- Navigation Sections -->
      <div class="lg:col-span-3 grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-4">
        <div>
          <h3 class="font-sans text-base font-semibold text-white">For Individuals</h3>
          <ul role="list" class="mt-2 space-y-2">
            <li v-for="item in navigation.individuals" :key="item.name">
              <a :href="item.href" class="font-sans text-base font-light text-white hover:text-secondary-300">
                {{ item.name }}
              </a>
            </li>
          </ul>
        </div>

        <div>
          <h3 class="font-sans text-base font-semibold text-white">For Organisations</h3>
          <ul role="list" class="mt-2 space-y-2">
            <li v-for="item in navigation.organisations" :key="item.name">
              <a :href="item.href" class="font-sans text-base font-light text-white hover:text-secondary-300">
                {{ item.name }}
              </a>
            </li>
          </ul>
        </div>

        <div>
          <h3 class="font-sans text-base font-semibold text-white">About</h3>
          <ul role="list" class="mt-2 space-y-2">
            <li v-for="item in navigation.solutions" :key="item.name">
              <a :href="item.href" class="font-sans text-base font-light text-white hover:text-secondary-300">
                {{ item.name }}
              </a>
            </li>
          </ul>
        </div>

        <div>
          <h3 class="font-sans text-base font-semibold text-white">Resources</h3>
          <ul role="list" class="mt-2 space-y-2">
            <li v-for="item in navigation.advice" :key="item.name">
              <a :href="item.href" class="font-sans text-base font-light text-white hover:text-secondary-300">
                {{ item.name }}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <!-- Footer Bottom -->
    <div class="mt-12 border-t border-gray-200 pt-8">
      <p class="font-sans text-white text-center xl:text-left">
        &copy; 2024 Youlio Health Ltd. All rights reserved.
      </p>
    </div>
    <div class="flex flex-row items-center justify-start space-x-2 p-2 sm:mx-auto lg:justify-start">
      <a href="/privacy" class="hover:bg-white/20">
        <div class="font-sans font-extralight text-white">
          Privacy
        </div>
      </a>
      <span class="text-white">|</span>
      <a href="/terms" class="hover:bg-white/20">
        <div class="font-sans font-extralight text-white">
          Terms
        </div>
      </a>
    </div>
  </div>
</footer>
<!-- END Footer -->






<!-- Include the CookieConsent component here -->
<CookieConsent />


</template>

<script setup>

  import { defineComponent, h } from 'vue'
  import Banner from '../website/components/Banner' ;
  import CookieConsent from '../website/components/CookieConsent' ;
  import { Popover, PopoverButton, PopoverGroup, PopoverPanel } from '@headlessui/vue'
  import { SwatchIcon, Bars3Icon, WrenchIcon, SunIcon, XMarkIcon, QuestionMarkCircleIcon, BanknotesIcon, BeakerIcon, BoltIcon, HomeIcon, ArrowRightOnRectangleIcon } from '@heroicons/vue/24/outline'
  import { ChevronDownIcon } from '@heroicons/vue/24/solid'
  import { ref, onMounted, onBeforeUnmount } from 'vue';

  const isDropdownOpen = ref(false);
const dropdownMenu = ref(null);
const dropdownButton = ref(null);

const toggleDropdown = () => {
  if (dropdownMenu.value) {
    dropdownMenu.value.classList.toggle('hidden');
  }
  isDropdownOpen.value = !isDropdownOpen.value;
};

const closeDropdown = (event) => {
  if (
    dropdownButton.value &&
    !dropdownButton.value.contains(event.target) &&
    dropdownMenu.value &&
    !dropdownMenu.value.contains(event.target)
  ) {
    if (!dropdownMenu.value.classList.contains('hidden')) {
      dropdownMenu.value.classList.add('hidden');
    }
    isDropdownOpen.value = false;
  }
};

onMounted(() => {
  window.addEventListener('click', closeDropdown);
});

onBeforeUnmount(() => {
  window.removeEventListener('click', closeDropdown);
});
 


  const individual = [
    {
      name: 'Weekly Therapy',
      href: '/individuals/weekly-therapy',
    },
    {
      name: 'Mental Health Programs',
      href: '/individuals/mental-health-programs',
    },
    {
      name: 'Wellbeing MOT',
      href: '/individuals/mental-health-mot',
    }, 
  ]

  const organisation = [
    {
      name: 'Schools & Universities',
      href: '/organisations/schools-universities',
    },  
    {
      name: 'Startups & Small Businesses ',
      href: '/organisations/startups-small-businesses',
    },
    {
      name: 'Charities and Non-Profits',
      href: '/organisations/charities-nonprofits',
    },
  ]

  const about = [
    {
      name: 'Me and My Approach',
      href: '/approach',
    },  
    {
      name: 'The Youlio Platform ',
      href: '/platform',
    },
    {
      name: 'Partner With Youlio',
      href: '/partnerships',
    },

    {
      name: 'FAQs',
      href: '/FAQ',
    },

   
  ]

  const resources = [
   
    {
      name: 'Common Problems',
      href: '/common-problems',
    },
    {
      name: 'What Therapy can Provide',
      href: '/therapy-provides',
    },
    { 
      name: 'Types of Therapy', 
      href: '/types-of-therapy', 
    }, 
  ]

  const sidebar = [
    {
      name: 'Home',
      href: '/',
      icon: HomeIcon,
    },  
    {
      name: 'Starting Therapy',
      href: '/initial-steps',
      icon: BoltIcon,
    },
    {
      name: 'My Approach',
      href: '/our-approach',
      icon: BeakerIcon,
    },
    { 
      name: 'Pricing', 
      href: '/pricing', 
      icon: BanknotesIcon 
    },
    { 
      name: 'FAQ', 
      description: "Your customers' data will be safe and secure.", 
      href: '/faq', 
      icon: QuestionMarkCircleIcon 
    },
    
    
    
  ]
  const navigation = {
    individuals: [
      { name: 'Weekly Therapy', href: '/individuals/weekly-therapy' },
      { name: 'Mental Health Programs', href: '/individuals/mental-health-programs' },
      { name: 'Mental Health MOT', href: '/individuals/mental-health-mot' },
      
    ],
    organisations: [
      { name: 'Schools and Universities', href: '/organisations/schools-universities' },
      { name: 'Startups and Businesses', href: '/organisations/startups-small-businesses' },
      { name: 'Charities and Non-profits', href: '/organisations/charities-nonprofits' },
    ],
    solutions: [
      { name: 'Me and My Approach', href: '/approach' },
      { name: 'The Youlio Platform', href: '/platform' },
      { name: 'Pricing', href: '/pricing' },
      { name: 'Partnerships', href: '/partnerships' },
      { name: 'FAQs', href: '/faq' },
      { name: 'Contact', href: '/contact' },
    ],
    advice: [
      { name: 'Common Problems', href: '/common-problems' },
      { name: 'What Therapy Can Provide', href: 'therapy-provides' },
      { name: 'Types of Therapy', href: '/types-of-therapy' },
      { name: 'Helplines', href: '/helplines' },
    ],
    legal: [
      { name: 'Privacy', href: '/privacy' },
      { name: 'Terms', href: '/terms' },
    ],
    social: [
      {
        name: 'Facebook',
        href: 'https://www.facebook.com/youliohealth/',
        icon: defineComponent({
          render: () =>
            h('svg', { fill: 'currentColor', viewBox: '0 0 24 24' }, [
              h('path', {
                'fill-rule': 'evenodd',
                d: 'M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z',
                'clip-rule': 'evenodd',
              }),
            ]),
        }),
      },
      {
        name: 'Instagram',
        href: 'https://www.instagram.com/youliohealth/',
        icon: defineComponent({
          render: () =>
            h('svg', { fill: 'currentColor', viewBox: '0 0 24 24' }, [
              h('path', {
                'fill-rule': 'evenodd',
                d: 'M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z',
                'clip-rule': 'evenodd',
              }),
            ]),
        }),
      },
      
  
          
    ],
  }

</script>

<script>
export default {
  data() {
    return {
      showDiv: true,
      scrollPosition: null,
      openSection: null // Added for collapsible menu
    };
  },
  methods: {
    hideDiv() {
      this.showDiv = false;
    },
    updateScroll() {
      this.scrollPosition = window.scrollY;
    },
    toggleSection(section) {
      this.openSection = this.openSection === section ? null : section; // Toggles the section open/closed
    }
  },
  mounted() {
    window.addEventListener('scroll', this.updateScroll);

    const route = this.$route;

    if (route.query.utm_source) {
      localStorage.setItem('utm_source', route.query.utm_source);
    }

    if (route.query.utm_medium) {
      localStorage.setItem('utm_medium', route.query.utm_medium);
    }

    if (route.query.utm_campaign) {
      localStorage.setItem('utm_campaign', route.query.utm_campaign);
    }

    if (route.query.utm_content) {
      localStorage.setItem('utm_content', route.query.utm_content);
    }

    if (route.query.utm_term) {
      localStorage.setItem('utm_term', route.query.utm_term);
    }
  }
};
</script>

<style scoped>
   .change_color {
       background-color:rgb(255, 255, 255);
       box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
   }
   
</style>
