<template>

<!-- SECTION-Hero -->
<BlockHero
      headline="Pricing"
      subheadline=""
      bgClass="bg-secondary-600"
      theme="dark"
      paddingTopClass="pt-20 sm:pt-16 md:pt-28"
      paddingBottomClass="pb-8 sm:pb-16 md:pb-4"
    />

  
   <Wave colorClass="fill-secondary-600" shadow position="up" />  
   <section class="bg-secondary-600 pt-8 sm:pt-16 sm:pb-20">

     <!-- SECTION-Weekly Therapy -->
     <section class="pb-16 sm:pb-20">
    <div class="mx-auto max-w-3xl px-6 text-center lg:px-8">
      <div class="mx-auto max-w-3xl">
        <h2 class="mt-2 ws-text-heading-sm text-white">Weekly Therapy</h2>
      </div>
      <div class="relative mt-6">
        <p class="mx-auto ws-text-body-base text-white/90">Choose the session length that best fits your needs. Whether you prefer a shorter, focused session or a more in-depth discussion, I offer flexible options to accommodate your preferences.</p>
      </div>
    </div>

        <div class="pt-16 mx-auto max-w-7xl px-6 lg:px-8">
          <div class="mx-auto grid max-w-md grid-cols-1 gap-8 lg:max-w-4xl lg:grid-cols-2">
            <div v-for="tier in tiers" :key="tier.id" class="flex flex-col justify-between rounded-3xl bg-white p-8 shadow-xl ring-1 ring-gray-900/10 sm:p-10">
              <div>
                <h3 :id="tier.id" class="ws-text-body-base-bold text-primary-600">{{ tier.name }}</h3>
                <div class="mt-4 flex items-baseline gap-x-2">
                  <span class="ws-text-heading-lg text-gray-800">{{ tier.priceMonthly }}</span>
                  <span class="ws-text-body-sm text-gray-600">/session</span>
                </div> 
              </div>
              <a :href="tier.href" :aria-describedby="tier.id" class="mt-8 text-center block ws-cta-primary">Get started today</a>
            </div>
            <div class="mt-4 border border-white/30 flex flex-col items-start gap-x-8 gap-y-6 rounded-3xl p-8 ring-1 ring-white/10 sm:gap-y-10 sm:p-10 lg:col-span-2 lg:flex-row lg:items-center">
              <div class="lg:min-w-0 lg:flex-1">
                <h3 class="ws-text-body-base-bold text-white">Low Cost Support</h3>
                <p class="ws-text-body-sm text-white mt-2">I strongly believe in making mental health support accessible to everyone so I offer low-cost therapy to individuals facing financial challenges. Feel free to reach out if you’d like to know more.</p>
              </div>
              <a href="mailto:tom@youliohealth.com?subject=I'm%20Interested%20in%20Your%20Low%20Cost%20Support" class="rounded-md px-3.5 py-2 text-base font-semibold leading-6 text-white ring-1 ring-inset ring-primary-200 hover:ring-primary-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600">Contact Me<span aria-hidden="true"> &rarr;</span></a>
            </div>
          </div>
        </div>
      
    </section>

<!-- SECTION-Programs -->
<section>
<Wave colorClass="fill-secondary-600" shadow position="up" />  
      <div class=" pt-16 pb-4 sm:pb-20">
    <div class="mx-auto max-w-3xl px-6 text-center lg:px-8">
      <div class="mx-auto max-w-3xl">
        <h2 class="mt-2 ws-text-heading-sm text-white">Mental Health Programs</h2>
      </div>
      <div class="relative mt-6">
        <p class="mx-auto ws-text-body-base text-white/90">Enhance your mental health through personalised programs that combine expert guidance, tailored sessions, and practical resources to support long-term well-being and growth.</p>
      </div>
    </div>
    <div class=" py-16 px-6">
      <div class="mx-auto max-w-2xl rounded-3xl ring-1 ring-gray-200 lg:flex lg:max-w-6xl ">
        <div class="p-8 sm:p-10 lg:flex-auto">
        
          <p class="mt-6 text-base leading-7 text-white">Choose a program and the duration of either 6, 9 or 12 weeks.</p>
          <div class="mt-10 flex items-center gap-x-4">
            <h4 class="flex-none text-sm font-semibold leading-6 text-white">Each program includes:</h4>
            <div class="h-px flex-auto bg-gray-100" />
          </div>
          <ul role="list" class="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-white sm:grid-cols-2 sm:gap-6">
            <li v-for="feature in includedProgramFeatures" :key="feature" class="flex gap-x-3">
              <CheckIcon class="h-6 w-5 flex-none text-white" aria-hidden="true" />
              {{ feature }}
            </li>
          </ul>
        </div>
        <div class="-mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0">
          <div class="rounded-2xl bg-gray-50 py-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-16">
            <div class="mx-auto max-w-xs px-8">
              <p class="text-base font-semibold text-gray-600">Payment is weekly</p>
              <p class="mt-6 flex items-baseline justify-center gap-x-2">
                <span class="text-5xl font-bold tracking-tight text-gray-900">£50</span>
                <span class="text-sm font-semibold leading-6 tracking-wide text-gray-600">/Week</span>
              </p>
              <a href="/register/overview-programs" class="mt-10 block w-full ws-cta-primary">Get started</a>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


<!-- SECTION-MOT -->
<section>
<Wave colorClass="fill-secondary-600" shadow position="up" />  


<div class="">
    <div class="mx-auto max-w-4xl pt-16 px-6 lg:px-8">
      <div class="mx-auto max-w-2xl sm:text-center">
        <h2 class="mt-2 ws-text-heading-sm text-white">My Wellbeing MOT</h2>
        <p class="mx-auto ws-text-body-base text-white/90">Invest in your mental well-being with a comprehensive 90-minute session and a collection of readings and questionnaires designed to provide deep insights and actionable steps.</p>
      </div>
    </div>
  </div>
  <div class=" pt-16 pb-28 px-6">
        <div class="mx-auto max-w-2xl rounded-3xl ring-1 ring-gray-200 lg:flex lg:max-w-6xl ">
          <div class="p-8 sm:p-10 lg:flex-auto">
            
            <p class="mt-6 text-base leading-7 text-white">Take a deep dive into your mental health with my mental wellbeing MOT.</p>
            <div class="mt-10 flex items-center gap-x-4">
              <h4 class="flex-none text-sm font-semibold leading-6 text-white">Each MOT includes:</h4>
              <div class="h-px flex-auto bg-gray-100" />
            </div>
            <ul role="list" class="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-white sm:grid-cols-2 sm:gap-6">
              <li v-for="feature in includedMOTFeatures" :key="feature" class="flex gap-x-3">
                <CheckIcon class="h-6 w-5 flex-none text-white" aria-hidden="true" />
                {{ feature }}
              </li>
            </ul>
          </div>
          <div class="-mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0">
            <div class="rounded-2xl bg-gray-50 py-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-16">
              <div class="mx-auto max-w-xs px-8">
                <p class="text-base font-semibold text-gray-600">Discover, Understand, Thrive</p>
                <p class="mt-6 flex items-baseline justify-center gap-x-2">
                  <span class="text-5xl font-bold tracking-tight text-gray-900">£99</span>
                  <span class="text-sm font-semibold leading-6 tracking-wide text-gray-600"></span>
                </p>
                <a href="/register/overview-wellbeing-mot" class="mt-10 block w-full ws-cta-primary">Get started</a>
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>


  </section>



<!-- SECTION-For Organisations -->
  <section class="py-16 bg-secondary-100">
    <div class="overflow-x-hidden px-6 max-w-4xl mx-auto">
      <h3 class="font-bold text-2xl text-gray-800 text-left sm:max-w-xl">
      For Organisations
    </h3>   
      <p class="mt-8 ws-text-body-base text-gray-800 text-opacity-75" >
        I understand that every organisation is unique. That’s why I offer customised programs and pricing based on your specific needs. Whether you’re a school, business, or charity, I’ll work closely with you to design the right mental health solution.</p>
        <div class="py-8">
          <div class="border border-primary-600 flex flex-col items-start gap-x-8 gap-y-6 rounded-3xl p-8 ring-1 ring-white/10 sm:gap-y-10 sm:p-10 lg:col-span-2 lg:flex-row lg:items-center">
              <div class="lg:min-w-0 lg:flex-1">         
              <p class="ws-text-body-sm text-gray-800">Contact me to discuss tailored solutions for your organisation.</p>
            </div>
            <a href="mailto:tom@youliohealth.com?subject=I'm%20Interested%20in%20Your%20Work%20with%20Organisations" class="bg-primary hover:bg-primary-500 rounded-lg px-4 py-2 text-base font-semibold leading-6 text-white">
              Get In Touch <span aria-hidden="true">&rarr;</span></a>
          </div>
        </div>
      </div>

</section> 

</template>

<script setup>
      
import BlockCTA from '../../website/components/BlockCTA' ;
import BlockHero from '../../website/components/BlockHero' ;

import BlockPricingTherapy from '../../website/components/BlockPricingTherapy' ;
import BlockPricingPrograms from '../../website/components/BlockPricingPrograms' ;
import BlockPricingMOT from '../../website/components/BlockPricingMOT' ;
import Wave from '../../website/components/Wave' ;

import { CheckIcon, CalendarIcon, ClipboardIcon, KeyIcon } from '@heroicons/vue/24/outline' 

const includedProgramFeatures = [
  '30-minute 1:1 weekly therapy sessions',
  'Ongoing communication and support througout the program',
  'Access to all of Youlios features',
]

const includedMOTFeatures = [
    '90-minute deep dive session ',
    'Collection of readings and questionnaires prior to the session',
    'Personalised action plan',
  ]

  const tiers = [
  {
    name: '30-minute session',
    id: 'tier-hobby',
    href: '/register/overview-weekly-therapy',
    priceMonthly: '£50',
  },
  {
    name: '50-minute session',
    id: 'tier-team',
    href: '/register/overview-weekly-therapy',
    priceMonthly: '£70',
    
  },
]
</script>
