<template>
  
  <div class="min-h-screen bg-white"> 

    <!--Blur background-->
    <div class="min-h-screen relative isolate overflow-hidden">
      <div class="absolute -top-80 left-[max(6rem,33%)] -z-10 transform-gpu blur-3xl sm:left-1/2  lg:ml-20 xl:top-3 xl:ml-56" aria-hidden="true">
      <div class="opacity-40 aspect-[801/1036] w-[50.0625rem] bg-gradient-to-tr from-[#6A99BA] to-[#ADD4F0] " style="clip-path: polygon(63.1% 29.6%, 100% 17.2%, 76.7% 3.1%, 48.4% 0.1%, 44.6% 4.8%, 54.5% 25.4%, 59.8% 49.1%, 55.3% 57.9%, 44.5% 57.3%, 27.8% 48%, 35.1% 81.6%, 0% 97.8%, 39.3% 100%, 35.3% 81.5%, 97.2% 52.8%, 63.1% 29.6%)" />
    </div>

    <div class="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80" aria-hidden="true">
        <div class="opacity-20 relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#6A99BA] to-[#ADD4F0] sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]" style="clip-path: polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)" />
      </div>
  <!--END background-->

    <div class="flex flex-col justify-center pt-16 sm:px-6 lg:px-8">
    <div class="sm:mx-auto sm:w-full sm:max-w-md">
      <a href="/">
      <img class="mx-auto h-12 w-auto" src="img/logo-dark.svg" alt="Youlio logo" />
    </a>
      <h2 class="mt-16 text-center ws-text-body-lg text-gray-800">Log In to Your Account</h2>
    
    </div>
  
  <div class="flex flex-col justify-center items-center px-2">
   
    <div class="w-full sm:max-w-md mt-6 px-6 py-4 overflow-hidden">
      <form @submit.prevent="login">
        <div>
          <label class="ws-text-label" for="email">
            Email
          </label>
          <input v-model="data.email" class="focus:ring-transparent focus:border-gray-400 p-2 rounded-md shadow-sm bg-white border border-gray-300 text-gray-700 block mt-1 w-full" id="email" type="email" name="email" required="required">
        </div>
        <div class="mt-4">
          <label class="ws-text-label" for="password">
            Password
          </label>
          <input v-model="data.password" class="focus:ring-transparent focus:border-gray-400 p-2 rounded-md shadow-sm bg-white border border-gray-300 text-gray-700 block mt-1 w-full" id="password" type="password" name="password" required="required" autocomplete="current-password">
        </div>
        <div v-if="errors" class="text-red-500 py-2 font-semibold">
          <span>{{ errors.message }}</span>
        </div>
<!--Remember me and forgot password-->
        <div class="flex items-center justify-between mt-4">
          <label for="remember" class="inline-flex items-center">
            <input v-model="data.remember" id="remember" type="checkbox" class="rounded border border-gray-300 text-primary shadow-sm focus:ring-transparent" name="remember">
            <span class="ml-2 ws-text-body-sm gray-700">Remember me</span>
          </label>
          <router-link class="underline ws-text-body-sm text-gray-700" :to="{name: 'ForgotPassword'}">
            Forgot your password?
          </router-link>
        </div>
<!--Login Button-->
        <div class="mt-12 md:px-16">
          <button type="submit" class="mx-auto w-full ws-cta-primary">
            Log in
          </button>
        </div>
      </form>
    
    <div class="mt-4 text-center ws-text-body-sm text-gray-700">
        Don't have an account yet?
        <router-link class="underline hover:text-primary-600 ws-text-body-sm text-gray-700" :to="{name: 'RegisterOverviewConsult'}">
          Sign up now
        </router-link>
      </div>
    </div>
  </div>
</div>
</div>
</div>
</template>

<script>

export default {
  data: () => {
    return {
      errors: null,
      data: {
        email: null,
        password: null,
        remember: null,
      },
    }
  },
  components: {
    
  },
  methods: {
    login() {
      this.errors = null

      axios.get('/sanctum/csrf-cookie').then(response => {
        axios.post('/login', this.data)
          .then((response) => {
            if(response?.status === 200 && response.data?.two_factor) {
              this.$router.replace({name: 'TwoFactorChallenge'})
              return
            }

            this.$store.dispatch('attempt_user')
              .then((response) => {
                if(response?.status === 200) {
                  this.$router.replace({name: 'Home'})
                }
              })
              .catch((error) => {
                this.errors = error.response.data
              })
          })
          .catch((error) => {
            this.errors = error.response.data
          })
      });
    }
  }
}
</script>
