<template>
  <div class="charities-non-profits-page">
  
<!-- SECTION-Hero -->
    <BlockHero
      headline="Enhance Well-being in Charities and Non-Profits"
      subheadline=""
      bgClass="bg-gray-800"
      theme="dark"
    />

    <!-- SECTION-Introduction -->
    <BlockContent
      overline="Empowering Mental Health"
      headline="Why Youlio is Ideal for Charities and Non-Profits"
      subheadline="Charities and non-profits often face unique challenges, including limited resources and high-stress environments. Youlio offers customisable mental health solutions that support your team’s well-being while aligning with your organisational values and mission."
      bgClass="bg-white"
      theme="light"
      layout="image-right"
      imageSrc="/img/website/charities-why-choose-youlio.jpg"
    />

    <Wave colorClass="fill-white" shadow position="down" /> 
    <!-- SECTION-Key Features -->
<BlockKeyFeatures
      title="Key Features for Charities and Non-profits"
      :features="[
        { title: 'Scalable Solutions', description: 'Tailored mental health programs that grow with your business, from small teams to larger, more complex organisations.', icon: KeyIcon },
        { title: 'Employee Wellness', description: 'Support your team’s mental health with proactive programs that enhance overall workplace wellness and productivity.', icon: BoltIcon },
        { title: 'Flexible Scheduling', description: 'Easily manage and schedule therapy sessions and workshops that fit into your teams busy schedules.', icon: CalendarIcon },
        { title: 'Confidential Support', description: 'Ensure that all interactions and data are kept confidential, providing a safe space for your team’s mental health.', icon: LockClosedIcon }
      ]"
    />
  

    <Wave colorClass="fill-white" shadow position="down" /> 
<!-- SECTION-My Commitment to Charities and Non-Profit -->
    <BlockContent
    overline="Championing Change"  
    headline="My Commitment to Charities and Non-Profits"
      subheadline="I am dedicated to supporting charities and non-profits by providing tailored mental health solutions that align with your mission and enhance your organisational impact. Using Youlio, I aim to help your team thrive and sustain your valuable work in the community."
      bgClass="bg-white"
      theme="light"
      layout="image-right"
      imageSrc="/img/website/me-4.jpg"
    />

   <!-- SECTION-Example Programs -->
   <Wave colorClass="fill-white" shadow position="down" /> 
      <BlockSwiper
        :slides="3"
        overline="Example Programs"
        headline="Nurturing the Wellbeing of Those Who Give"
        subheadline="Review programs supporting mental health in charities and non-profits, adaptable to your organisation's demands."
>
        <template #slide-0>
          <BlockContentInSwiperProgram
        headline="Managing Compassion Fatigue"
        subheadline="Focus on strategies to manage and prevent compassion fatigue. This program provides tools to help charity workers maintain their emotional resilience and effectiveness, ensuring they can continue to support others without sacrificing their own well-being."
        imageSrc="/img/website/program-compassion-fatigue.jpg"
        bgClass="bg-white"
        theme="light"
        layout="image-right"
        paddingTopClass="pt-4 sm:pt-4 md:pt-4"
        paddingBottomClass="pb-4 sm:pb-4 md:pb-4"
        />
        </template>
        <template #slide-1>
          <BlockContentInSwiperProgram
        headline="Boosting Workplace Confidence"
        subheadline="Enhance your self-assurance and effectiveness at work with this program designed to build confidence. Gain practical skills to assert yourself, communicate more effectively, and overcome self-doubt, leading to a more impactful and fulfilling role in your organisation."
        imageSrc="/img/website/program-confidence-2.jpg"
        bgClass="bg-white"
        theme="light"
        layout="image-right"
        paddingTopClass="pt-4 sm:pt-4 md:pt-4"
        paddingBottomClass="pb-4 sm:pb-4 md:pb-4"
        />
        </template>
        <template #slide-2>
          <BlockContentInSwiperProgram
        headline="Managing Your Anxiety"
        subheadline="Learn to take control of your anxiety and reduce its impact on your daily life. This program provides practical tools and therapeutic techniques to help you manage anxious thoughts and feelings, empowering you to live more freely."
        imageSrc="/img/website/program-anxiety-2.jpg"
        bgClass="bg-white"
        theme="light"
        layout="image-right"
        paddingTopClass="pt-4 sm:pt-4 md:pt-4"
        paddingBottomClass="pb-4 sm:pb-4 md:pb-4"
        />
        </template>
       
      </BlockSwiper>

    <Wave colorClass="fill-white" shadow position="down" /> 
<!-- SECTION-CTA Section -->
        <BlockCTA    
          heading="Ready to Support Your Mission?"
          message="Reach out today to learn more about how Youlio can assist your charity or non-profit in achieving its goals while ensuring the well-being of your team."
          primaryButtonText="Get in Touch"
          primaryButtonLink="mailto:tom@youliohealth.com?subject=I'm%20Interested%20in%20Your%20Work%20with%20Charities%20and%20Non-profits"
          imageSrc="/img/website/charities-cta.jpg"
          bgClass="bg-white"
          theme="light"
        />
        
  </div>
</template>

<script>
export default {
  name: "CharitiesNonProfitsPage",
};
</script>

<script setup>
      
import BlockCTA from '../../website/components/BlockCTA' ;
import Wave from '../../website/components/Wave' ;
import BlockKeyFeatures from '../../website/components/BlockKeyFeatures' ;
import BlockContentInSwiperProgram from '../../website/components/BlockContentInSwiperProgram' ;
import BlockHero from '../../website/components/BlockHero' ;
import BlockSwiper from '../../website/components/BlockSwiper' ;
import BlockContent from '../../website/components/BlockContent' ;
import DisclosureContent from '../../website/components/DisclosureContent' ;
import BlockStructureTitle from '../../website/components/BlockStructureTitle' ;
import { KeyIcon, BoltIcon, CalendarIcon, LockClosedIcon } from '@heroicons/vue/24/outline'
import { ChevronRightIcon } from '@heroicons/vue/20/solid'   

</script>
