<template>
<!-- Title -->
<div class="">
    <div v-for="collection in collections">
      <div class="flex justify-between items-center mt-8 md:mt-12 mb-2">
        <router-link :to="'collections/' + collection.slug" >
        <h1 class="text-page-subtitle-dark">My Mental Health</h1>
      </router-link>
        <router-link :to="'collections/' + collection.slug" >
          <div class="flex items-center justify-center text-primary-500 hover:text-primary-400">
      <h3 class="text-sm font-medium">See more</h3>
      <chevron-right-icon class="chevron-dashboard" />
    </div>
        </router-link>
      </div>
<!-- END Title -->

<!-- Card --> 

<div class="mb-4 shadow-xl shadow-gray-300/30 card-edges bg-white max-w-lg">
        

<div v-if="collection.until_repeat > 0" class="pt-3 px-4 pb-3">
            <p class="text-display-base-alpha text-primary text-start">{{ collection.until_repeat }} DAYS
            <span class="text-body-sm"> until next Check-In</span></p>
          </div>
          <div v-else class="pt-3 px-4 pb-3">
            
            <router-link :to="'collections/' + collection.slug"> 
            <div class=" flex items-center justify-start">
            <p class="  font-normal text-base text-gray-600">Check-In is <span class="font-semibold text-gray-700">READY</span></p>
            
            <span>
              <ChevronRightIcon class="animate-bounce ml-2 h-6 w-auto text-white bg-primary hover:bg-primary-400 rounded-full" />
              </span>
            </div>
            </router-link>
            
          </div>
          </div>

        <div class=" card-edges bg-white shadow-xl shadow-gray-300/10 max-w-lg pb-8">
          <img class="object-cover bg-primary top-edge-rounded h-6 w-full" src="
          https://images.unsplash.com/photo-1579546928686-286c9fbde1ec?q=80&w=3039&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D
          " alt="Image to represent the reading being displayed" />
          
          <div v-for="questionnaire in collection.questionnaires">
            <div v-for="(factor, index) in questionnaire.calculated_scores" :key="factor.name" class="mt-6 px-4 md:mt-6">
              
              <div class="flex flex-row items-center justify-between w-full">
                <p class="flex text-dashboard-health-factor">{{ factor.name }}
                <span v-if="factor.diff" :class="[isBadDirection(factor) ? 'text-red-600' : 'text-emerald-500', 'ml-4 flex items-center']">
                  <component :is="factor.positive ? ArrowSmallUpIcon : ArrowSmallDownIcon" class="stroke-2 h-3 w-3" />
                </span>
                <span v-if="factor.diff" :class="[isBadDirection(factor) ? 'text-red-600' : 'text-emerald-500','font-semibold text-sm my-auto']">
                  {{ factor.diff }}%
                </span></p>
                <div class="flex items-center justify-between space-x-2">
                  <p class="text-dashboard-health-score">{{ factor.value }}%</p>
                </div>
              </div>
              <div class="mt-1 w-full h-2 bg-secondary-100 rounded-md">
                <div class="h-2 rounded-md hover:bg-emerald-600" :style="{ width: factor.value + '%', 'background-color': getFactorColor(factor) }">
                </div>
              </div>
              </div>
            </div>
            <!--Next Check In--> 
         
        </div>
      
    </div>
  </div>
<!-- END Card --> 
</template>
  
<script setup>
  
import { ArrowSmallUpIcon, ArrowSmallDownIcon } from '@heroicons/vue/24/outline'
import { ChevronRightIcon } from '@heroicons/vue/20/solid'
import { sendPost, generateColorCode } from './entities/functions.js';

</script>

<script>
export default {
  data: function () {
    return {
      collections: []
    }
  },

  mounted() {
    this.getData();
  },

  methods: {
    async getData() {
      const responce = await sendPost('/api/collections', { page: 'dashboard' });
      if (responce) {
        this.collections = responce.collections;
      }
    },
    
    getFactorColor(factor) {
      if (factor.color) {
        return factor.color;
      }
      return generateColorCode(factor.name);
    },

    isBadDirection(factor) {
      let state = factor.positive;
      if (factor.is_increase_positive) {
        state = !state;
      }
      return state;
    }
  }
}
</script>