<template>
  
  <div class="min-h-screen bg-white"> 

    <!--Blur background-->
    <div class="min-h-screen relative isolate overflow-hidden">
      <div class="absolute -top-80 left-[max(6rem,33%)] -z-10 transform-gpu blur-3xl sm:left-1/2  lg:ml-20 xl:top-3 xl:ml-56" aria-hidden="true">
      <div class="opacity-40 aspect-[801/1036] w-[50.0625rem] bg-gradient-to-tr from-[#6A99BA] to-[#ADD4F0] " style="clip-path: polygon(63.1% 29.6%, 100% 17.2%, 76.7% 3.1%, 48.4% 0.1%, 44.6% 4.8%, 54.5% 25.4%, 59.8% 49.1%, 55.3% 57.9%, 44.5% 57.3%, 27.8% 48%, 35.1% 81.6%, 0% 97.8%, 39.3% 100%, 35.3% 81.5%, 97.2% 52.8%, 63.1% 29.6%)" />
    </div>

    <div class="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80" aria-hidden="true">
        <div class="opacity-20 relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#6A99BA] to-[#ADD4F0] sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]" style="clip-path: polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)" />
      </div>
  <!--END background-->

    <div class="flex flex-col justify-center pt-16 sm:px-6 lg:px-8">
    <div class="sm:mx-auto sm:w-full sm:max-w-md">
      <a href="/">
      <img class="mx-auto h-12 w-auto" src="img/logo-dark.svg" alt="Youlio logo" />
    </a>
      <h2 class="mt-16 text-center ws-text-heading-thin-black" data-aos="slide-left" data-aos-once="true" data-aos-duration="1000">Verify your email</h2>
    
    </div>
<div class="mx-auto pt-8 px-12 sm:max-w-lg">
      <p class="ws-text-body-sm text-center">
        We have sent an email to 
        <br/><span class="ws-text-display-base">{{ email }}</span> 
        <br/>to verify that it's you.
        <br/></p>
        <p class="px-8 md:px-0 pt-4 text-italic text-center font-medium text-xs text-gray-500">
          (don't forget to check your junk mail in case it accidentally ended up in there)
        </p>
        <div class="border-t mt-4 mb-8"></div>
        <p class="md:mx-20 ws-text-body-sm text-center">
        If you didn't receive the email click below and we can resend it.</p>
    </div>
      <div class="flex flex-col justify-center items-center px-2">
   
   <div class="w-full sm:max-w-md px-6 py-4 overflow-hidden">

      <form @submit.prevent="resendVerification">
        <div v-if="errors" class="text-red-500 py-2 font-semibold">
          <span>{{ errors.message }}</span>
        </div>
          <div class="px-10 md:px-16">
          <button type="submit" class="ws-button-primary-blue">
            Resend Verification
          </button>
        </div>
      </form>
    </div>
   
  </div>
</div>
</div>
</div>
</template>

<script>
export default {
  data() {
    return {
      errors: null,
      email: null,
    }
  },

  mounted() {
    this.email = localStorage?.getItem("email");
  },

  methods: {
    resendVerification() {
      this.errors = null
      axios.post('/email/verification-notification')
        .then((response) => {
          console.log(response)
        })
        .catch((error) => {
          this.errors = error.response.data
        })
    }
  }
}
</script>
