<template>
  <div>

<!-- SECTION-Hero -->
    <BlockHero
      headline="Personalised Mental Health Support Just for You"
      subheadline=""
      bgClass="bg-secondary-800"
      theme="dark" 
      bgImage=""
    />
    <img class="" src="img/website/wave-dark-blue-white-1.jpg" alt="Youlio logo" />
<!-- SECTION-Introduction Video -->
        <section class="px-8 sm:px-6 max-w-4xl mx-auto pt-8 pb-12 sm:py-20">
        <video class="mb-8 mx-auto sm:max-w-md rounded-xl shadow-lg" controls playsinline preload="metadata" 
          :poster="shouldUsePoster ? '/img/website/video-poster-me-1.jpg' : null">
            <source src="/img/website/landing-video-2.mp4" type="video/mp4">
            Your browser does not support the video tag.
          </video>
          <div class="max-w-3xl mx-auto border-2 border-primary-600 border-opacity-30 rounded-2xl p-4 sm:p-6">
          <p class=" text-left ws-text-body-lg text-gray-600">
            "With Youlio, I’m committed to guiding you on your unique journey to better mental health. Each person's path is different, and that's why I offer three tailored options to best support your needs."
        </p>       
      </div>
        </section>

<!-- SECTION-Three Services --> 
<img class="" src="img/website/wave-white-1.jpg" alt="Youlio logo" />
    <section>  
    <BlockContent
      overline="Prioritising Your Needs"
      headline="Discover the Right Path For You"
      subheadline="Whether you're seeking a one-off MOT, a structured program, or ongoing weekly therapy, I'm here to help you find the right fit. Let’s explore the options:"
      bgClass="bg-white"
      theme="light"
      paddingTopClass="pt-16 sm:pt-20 md:pt-28"
      paddingBottomClass="pb-0 sm:pb-0 md:pb-0"
    /> 
    <BlockThreeCards
  :card1="{
    icon: VideoCameraIcon, 
    title: 'Weekly Therapy', 
    description: 'Ongoing one-on-one sessions for consistent support and guidance. Ideal for those who want regular check-ins and a safe space to work through challenges.', 
    link: '/individuals/weekly-therapy', 
    ctaText: 'Learn More', 
    bgColor: 'primary-100'
  }"
  :card2="{
    icon: MapIcon, 
    title: 'Mental Health Programs', 
    description: 'Structured programs designed to address specific challenges like anxiety or low-self-esteem. Get a tailored plan and regular support to make meaningful progress.', 
    link: '/individuals/mental-health-programs', 
    ctaText: 'Learn More', 
    bgColor: 'gray-100'
  }"
  :card3="{
    icon: WrenchScrewdriverIcon, 
    title: 'Wellbeing MOT', 
    description: 'Questionnaires and readings followed by an in-depth extended session to explore your mental health and uncover immediate, actionable steps. ', 
    link: '/individuals/mental-health-mot', 
    ctaText: 'Learn More', 
    bgColor: 'secondary-100'
  }"
    />
    </section>  
         
<!-- SECTION-How Youlio Supports Your Journey -->
    <img class="" src="img/website/wave-white-1.jpg" alt="Youlio logo" />
      <BlockSwiper
        :slides="4"
        overline="Enhanced Care"
        headline="Maximising Your Therapy Experience with Youlio"
        subheadline="Youlio enables me to provide therapy-driven mental health support in a more effective and connected way. The platform integrates convenient tools and resources to streamline our sessions, track your progress, and offer continuous support."
>
        <template #slide-0>
          <BlockContentInSwiper
        headline="Smooth Sailing Sessions"
        subheadline="Youlio makes therapy sessions a breeze! The platform handles all of our sessions, making it super easy for us to connect."
        imageSrc="/img/website/landing-features-1.jpg"
        bgClass="bg-white"
        theme="light"
        layout="image-right"
        paddingTopClass="pt-4 sm:pt-4 md:pt-4"
        paddingBottomClass="pb-8 sm:pb-8 md:pb-8"
        />
        </template>
        <template #slide-1>
          <BlockContentInSwiper
        headline="Messaging"
        subheadline="You can reach out to me anytime with our messaging feature, so you're never alone on your journey."
        imageSrc="/img/website/landing-features-2.jpg"
        bgClass="bg-white"
        theme="light"
        layout="image-right"
        paddingTopClass="pt-4 sm:pt-4 md:pt-4"
        paddingBottomClass="pb-8 sm:pb-8 md:pb-8"
        />
        </template>
        <template #slide-2>
          <BlockContentInSwiper
        headline="Personalised Support"
        subheadline="With Youlio, I can send you readings, questionnaires, and activities that are completely tailored towards you."
        imageSrc="/img/website/landing-features-3.jpg"
        bgClass="bg-white"
        theme="light"
        layout="image-right"
        paddingTopClass="pt-4 sm:pt-4 md:pt-4"
        paddingBottomClass="pb-8 sm:pb-8 md:pb-8"
        />
        </template>
        <template #slide-3>
          <BlockContentInSwiper  
        headline="Progress Tracking"
        subheadline="Youlio enables us to monitor your journey throughout treatment, keeping us focused on the areas that matter most."
        imageSrc="/img/website/landing-features-4.jpg"
        bgClass="bg-white"
        theme="light"
        layout="image-right"
        paddingTopClass="pt-4 sm:pt-4 md:pt-4"
        paddingBottomClass="pb-8 sm:pb-8 md:pb-8"
        />
        </template>
      </BlockSwiper>

      <!-- SECTION-Testimonials--> 
      <BlockTestimonial
  :testimonials="[
    {
      text: 'My Therapy sessions with Tom have been life-changing. His empathic approach and practical advice have helped me navigate through my anxiety and build a healthier mindset. Highly recommend!',
      name: 'Daniel',
      age: 23
    },
    {
    text: 'Tom’s program helped me create lasting changes in my mental wellbeing. The practical strategies were so valuable to me. Highly recommended!',
    name: 'Emily',
    age: 27
  },
    {
      text: 'I love the Youlio platform, Tom and his genuine care have guided me through some tough times, helping me develop healthier coping mechanisms and a more positive outlook on life.',
      name: 'Michael',
      age: 45
    }
  ]"
  sectionBgColor="bg-primary-600"
/>
<!-- SECTION-How I Support Your Journey -->
    <BlockContent
    overline="Here For You"
    headline="Guiding You Every Step of the Way"
    subheadline="As a licensed therapist with extensive experience, I’m dedicated to supporting you throughout your journey with Youlio. I offer personalised guidance and expertise, ensuring that you receive tailored care in every 1:1 session. My goal is to be a constant, supportive presence, helping you navigate challenges and celebrate progress."
    secondaryCtaText="Learn More"
    secondaryCtaLink="/approach"
    imageSrc="/img/website/me-4.jpg"
    bgClass="bg-white"
    theme="light"
    layout="image-right"
    />


<!-- SECTION-CTA -->
<img class="" src="img/website/wave-white-1.jpg" alt="Youlio logo" />
<div class="pt-12 sm:pt-24 mx-auto max-w-3xl px-6 text-center lg:px-8">
  <div class="mx-auto max-w-3xl">
    <h2 class="mt-2 ws-text-heading-md text-gray-800">Ready to Make a Change?</h2>
  </div>
  <div class="relative mt-6">
    <p class="max-w-xl mx-auto ws-text-body-base text-gray-700">
      Taking the first step towards better mental health can be transformative. Reach out today and start your path to a better you.
    </p>
  </div>

  <!-- Drop-Down-CTA -->
  <div class="pt-8 text-center">
    <!-- Get Started Button -->
    <button
      @click="toggleDropdown"
      ref="dropdownButton"
      class="ws-cta-primary mx-auto"
    >
      Get Started
    </button>

    <!-- Dropdown Menu -->
    <transition name="fade" mode="out-in">
    <div
      v-show="dropdownVisible"
      ref="dropdownMenu"
      class="absolute bg-white shadow-md rounded mt-2 w-64 z-10 mx-auto"
      style="left: 50%; transform: translateX(-50%);"
    >
      <a
        href="/individuals/weekly-therapy"
        class="text-base font-semibold block px-4 py-2 text-gray-800 hover:bg-secondary-200"
      >
        Weekly Therapy
      </a>
      <a
        href="/individuals/mental-health-programs"
        class="text-base font-semibold block px-4 py-2 text-gray-800 hover:bg-secondary-200"
      >
        Mental Health Programs
      </a>
      <a
        href="/individuals/mental-health-mot"
        class="text-base font-semibold block px-4 py-2 text-gray-800 hover:bg-secondary-200"
      >
        My Wellbeing MOT
      </a>
    </div>
  </transition>
  </div>
</div>


  <BlockCTA    
      heading=""
      message=""
      primaryButtonText=""
      primaryButtonLink=""
      secondaryButtonText=""
      secondaryButtonLink=""
      imageSrc="/img/website/for-individuals-cta.jpg"
      bgClass="white"
      theme="light"
      paddingTopClass="pt-0 sm:pt-0 md:pt-0"
      paddingBottomClass="pb-16 sm:pb-20 md:pb-24"
    />

  </div>
</template>

<script setup>
      
import BlockCTA from '../../website/components/BlockCTA' ;
import Wave from '../../website/components/Wave' ;
import BlockHero from '../../website/components/BlockHero' ;
import BlockThreeCards from '../../website/components/BlockThreeCards' ;
import BlockContent from '../../website/components/BlockContent' ;
import BlockContentInSwiper from '../../website/components/BlockContentInSwiper' ;
import BlockSwiper from '../../website/components/BlockSwiper' ;
import BlockTestimonial from '../../website/components/BlockTestimonial' ;
import BlockStructureTitle from '../../website/components/BlockStructureTitle' ;
import { VideoCameraIcon, WrenchScrewdriverIcon, MapIcon, KeyIcon, CalendarIcon, CheckIcon, ClipboardIcon } from '@heroicons/vue/24/outline'
import { ChevronRightIcon } from '@heroicons/vue/20/solid'   

</script>



<script>
export default {
  data() {
    return {
      shouldUsePoster: true,
      dropdownVisible: false,
    };
  },
  methods: {
    toggleDropdown() {
      this.dropdownVisible = !this.dropdownVisible;
    },
    handleClickOutside(event) {
      if (
        this.$refs.dropdownMenu &&
        !this.$refs.dropdownMenu.contains(event.target) &&
        !this.$refs.dropdownButton.contains(event.target)
      ) {
        this.dropdownVisible = false;
      }
    },
  },
  mounted() {
    document.addEventListener("click", this.handleClickOutside);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.handleClickOutside);
  },
};
</script>

<style scoped>
/* Fade animation */
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.3s ease;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
