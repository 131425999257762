<template>
<div class="faq-page">

<!-- SECTION-Hero -->
    <BlockHero
      headline="FAQs"
      subheadline="Here are some of the questions I hear the most..."
      bgClass="bg-gray-800"
      theme="dark"
      paddingTopClass="pt-16 sm:pt-16 md:pt-24"
    paddingBottomClass="pb-12 sm:pb-16 md:pb-20"
    />

<!-- SECTION-FAQs -->
    <section>
    <!-- How it Works -->
    <BlockFAQ
    heading="How It Works"
    :faqs="[
        { question: 'What is Youlio?', answer: 'Youlio is an online therapy and mental health service provided by myself, Tom Mitchell. I provide weekly therapy, mental health programs and wellbeing MOT`s to indivudals and organisastions.' },
      { question: 'How much does it cost?', answer: 'Prices vary depending on the service you choose. Head over to the Youlio pricing page to get a detailed breakdown of the different costs available to you' },
      { question: 'Is Youlio carried out online?', answer: 'Yes, the Youlio platform provides user\'s with their own dedicated private, safe space. All therapy and mental health support is carried out by myself within this space.' },
      { question: 'Is Youlio private?', answer: 'Youlio uses secure, encrypted communication for video sessions and messaging, adhering to strict confidentiality standards and protection of your data. Your information is never shared without your consent.' },
      { question: 'What is evidence-based therapy?', answer: 'Evidence-based therapy is any therapy where scientific research has been carried out to prove that it does what it claims to do. Cognitive Behavioural Therapy is currently the most researched type of therapy with an overwhelming number of studies that show it\'s effectiveness at tackling mental health problems.' }
    ]"
    bgClass="bg-secondary-100"
    paddingTopClass="pt-16 sm:pt-20 md:pt-24"
    paddingBottomClass="pb-12 sm:pb-16 md:pb-0"
    />
    <!-- Treatment --> 
    <BlockFAQ
      heading="Your Journey"
      :faqs="[
        { question: 'How long does my journey with Youlio take?', answer: 'This depends ont the service you are after. Weekly therapy is ongoing and can be stopped at anytime, mental health programs are either 6, 9 or 12 weeks in length and the Wellbeing MOT is a one-off extended session.' },
        { question: 'What therapeutic approach do you use?', answer: 'My approach is based on a combination of therapies that are carried out using an overall structured framework. Cognitive Behavioural Therapy (CBT), Person-centered and Schema-based therapy are all commonly used and you can find explanations of each under the \'types of therapy\' page. This approach allows the therapeutic relationship to grow organically in its own way whilst maintaining an overall sense of progress which leads to positive change.' },
        { question: 'What is a treatment plan?', answer: 'I believe that effective help needs a plan of action and a treatment plan helps to keep the therapy pointing in the right direction. A treatment plan outlines what it is that the client is hoping to achieve and how this will be carried out. I believe that treatment plans work best when they are dynamic meaning they continue to evolve alongside the work itself.' },
        { question: 'What is a case formulation?', answer: 'A case formulation is a detailed look at what is currently going on. If we are trying to get somewhere it is helpful to know where we are coming from and so looking at how we are currently thinking, feeling and behaving is a vital part of the treatment process.' }
      ]"
      bgClass="bg-secondary-100"
      paddingTopClass="pt-4 sm:pt-20 md:pt-16"
      paddingBottomClass="pb-4 sm:pb-16 md:pb-0"
    />
    <!-- Account and Billing --> 
    <BlockFAQ
      heading="Account and Billing"
      :faqs="[
        { question: 'How do I create a Youlio account?', answer: 'Creating your own Youlio account is quick, easy and completely free. You will find \'Get Started\' links all over the website that will take you to the sign-up page.' },
        { question: 'How do I reset my password?', answer: 'Once logged into Youlio, you can simply click on account settings, navigate to the password section and use your current password to create a new one. If you\'ve forgotten your password you can click on the \'forgotten password\' link when trying to log into your account.' },
        { question: 'I can\'t get into my account', answer: 'If for some reason you cannot get into your Youlio account email us at hello@youliohealth.com and we will help to resolve the issue asap.' },
        { question: 'How do I cancel my account?', answer: 'If you would like to cancel your account just email us at hello@youliohealth.com and we will be able to close it down for you. Please note if you do cancel your account, you may lose any current activities you have as well as any progress tracking of your mental health.' },
      ]"
      bgClass="bg-secondary-100"
      paddingTopClass="pt-4 sm:pt-20 md:pt-16"
      paddingBottomClass="pb-4 sm:pb-16 md:pb-0"
    />
    <!-- Sessions & Appointments --> 
    <BlockFAQ
      heading="Sessions & Appointments"
      :faqs="[
        { question: 'How do I book a session?', answer: 'To book a session you can log into your Youlio account and visit your sessions page. Here, you can find a date and time that works for you and book in just a few clicks.' },
        { question: 'What if I miss my session?', answer: 'If you miss a session you can schedule another one but please bear in mind I have a 24 hours notice cancellation policy. This means if you haven\'t cancelled the session within 24 hours you will still be charged for the session. I recommend trying to find a regular time to carry out your sessions to ensure a level of consistency during the therapy process.' },
        { question: 'Can I reschedule a session?', answer: 'Yes you can. Lives are busy and things pop up so if needs be you can go to your sessions page and change the date and time of a scheduled therapy session.' },
        { question: 'Can I cancel a session I\'ve booked?', answer: 'If you want to cancel a session completely rather than reschedule there is an option to do so in your sessions page. Please note again that you will need to cancel within 24 hours otherwise you will still be charged for the session.' }
      ]"
      bgClass="bg-secondary-100"
      paddingTopClass="pt-4 sm:pt-20 md:pt-16"
      paddingBottomClass="pb-20 sm:pb-16 md:pb-24"
    />
    </section>
  </div>
</template>

<script setup>
  
  import BlockFAQ from '../../website/components/BlockFAQ' ;
  import BlockHero from '../../website/components/BlockHero' ;
  import BlockStructureTitle from '../../website/components/BlockStructureTitle' ;
  import { MinusSmallIcon, PlusSmallIcon, XMarkIcon } from '@heroicons/vue/24/outline'
  
</script>

<script>
export default {
  name: "FAQPage",
};
</script>
