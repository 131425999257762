<template>
  <div class="for-organisations-page">

<!-- SECTION-Hero -->
<BlockHero
      headline="Supporting Mental Health Across Organisations"
      subheadline=""
      bgClass="bg-gray-800"
      theme="dark" 
      bgImage="" 
    />
    <BlockContent
    overline=""
    headline=""
    subheadline="With Youlio, I work directly with organisations to create tailored mental health solutions that align with your unique needs. Together, we can build a supportive environment that prioritises well-being and drives meaningful change."
    imageSrc="/img/website/me-4.jpg"
    bgClass="bg-white"
    theme="light"
    layout="image-right"
    paddingTopClass="pt-16"
      paddingBottomClass="pb-20"  
    />
    
<!-- SECTION-What Organisations Do I Support --> 
      <Wave colorClass="fill-white" shadow position="down" /> 
      <section>   
      <BlockContent
      overline="Tailored Support"
      headline="What Organisations Do I Support?"
      subheadline="From educational institutions to growing businesses and mission-driven nonprofits, Youlio allows me to partner with your organisation to deliver mental health support where it’s needed most. "
      bgClass="bg-white"
      theme="light"
      paddingTopClass="pt-16 sm:pt-20 md:pt-28"
      paddingBottomClass="pb-0 sm:pb-0 md:pb-0"
    />

        <BlockThreeCards
      :card1="{
        icon: AcademicCapIcon, 
        title: 'Schools and Universities', 
        description: 'Supporting students and staff with targeted mental health programs that enhance well-being and resilience across your institution.', 
        link: 'organisations/schools-universities', 
        ctaText: 'Learn More', 
        bgColor: 'primary-100'
      }"
      :card2="{
        icon: BuildingOffice2Icon, 
        title: 'Startups and Small Businesses', 
        description: 'Helping teams navigate the challenges of growth with mental health strategies that foster productivity, creativity, and balance.', 
        link: '/organisations/startups-small-businesses', 
        ctaText: 'Learn More', 
        bgColor: 'gray-100'
      }"
      :card3="{
        icon: BuildingLibraryIcon, 
        title: 'Charities and Non-profits', 
        description: 'Empowering those who give back by providing accessible mental health support that aligns with your mission and values.', 
        link: '/organisations/charities-nonprofits', 
        ctaText: 'Learn More', 
        bgColor: 'secondary-100'
      }"
    />
    </section> 
<!-- SECTION-How Youlio Supports Your Journey -->
        <Wave colorClass="fill-white" shadow position="down" /> 
        <BlockSwiper
        :slides="4"
        overline="Streamlined Support"
        headline="Optimising Well-being with Youlio"
        subheadline="Youlio helps me deliver tailored mental health support more effectively. The platform’s integrated tools streamline interactions, track progress, and offer continuous support for diverse needs in various settings."
>
        <template #slide-0>
          <BlockContentInSwiper
        headline="Smooth Sailing Sessions"
        subheadline="Youlio makes therapy sessions a breeze! The platform handles all of our sessions, making it super easy for us to connect."
        imageSrc="/img/website/landing-features-1.jpg"
        bgClass="bg-white"
        theme="light"
        layout="image-right"
        paddingTopClass="pt-4 sm:pt-4 md:pt-4"
        paddingBottomClass="pb-8 sm:pb-8 md:pb-8"
        />
        </template>
        <template #slide-1>
          <BlockContentInSwiper
        headline="Messaging"
        subheadline="You can reach out to me anytime with our messaging feature, so you're never alone on your journey."
        imageSrc="/img/website/landing-features-2.jpg"
        bgClass="bg-white"
        theme="light"
        layout="image-right"
        paddingTopClass="pt-4 sm:pt-4 md:pt-4"
        paddingBottomClass="pb-8 sm:pb-8 md:pb-8"
        />
        </template>
        <template #slide-2>
          <BlockContentInSwiper
        headline="Personalised Support"
        subheadline="With Youlio, I can send you readings, questionnaires, and activities that are completely tailored towards you."
        imageSrc="/img/website/landing-features-3.jpg"
        bgClass="bg-white"
        theme="light"
        layout="image-right"
        paddingTopClass="pt-4 sm:pt-4 md:pt-4"
        paddingBottomClass="pb-8 sm:pb-8 md:pb-8"
        />
        </template>
        <template #slide-3>
          <BlockContentInSwiper  
        headline="Progress Tracking"
        subheadline="Youlio enables us to monitor your journey throughout treatment, keeping us focused on the areas that matter most."
        imageSrc="/img/website/landing-features-4.jpg"
        bgClass="bg-white"
        theme="light"
        layout="image-right"
        paddingTopClass="pt-4 sm:pt-4 md:pt-4"
        paddingBottomClass="pb-8 sm:pb-8 md:pb-8"
        />
        </template>
        </BlockSwiper>
   
<!-- SECTION-How I Support Your Organisation -->
      <Wave colorClass="fill-white" shadow position="down" /> 
      <BlockContent
      overline="Here For You "
      headline="How I Support Your Organisation"
      subheadline="As a licensed therapist with extensive experience, I’m committed to helping your organisation create a mentally healthy environment. Through Youlio, I offer personalised guidance tailored to your organisations unique needs, providing expert support in every session. My goal is to be a reliable partner in fostering well-being, helping your organisation to achieve a healthier, more resilient culture."
      imageSrc="/img/website/me-3.jpg"
      bgClass="bg-white"
      theme="light"
      layout="image-right"
      />
 <!-- SECTION-Why Choose Youlio -->
 <Wave colorClass="fill-white" shadow position="down" /> 
      <BlockWhyChoose
      overline="Personalised Support"
      headline="Why Choose Youlio?"
      subheadline="Combining my therapeutic expertise with Youlio's innovative platform creates a simple, highly effective therapy experience tailored specifically to your organisation’s needs."
      listImageSrc="/img/website/for-organisations-why-choose-youlio.jpg"
      listImageAlt="Why Choose Us"
      :listItems="[
        { title: 'Flexible and Accessible', description: 'Access therapy and mental health programs anytime, anywhere—perfect for modern organisations and remote teams.' },
        { title: 'Comprehensive Support', description: 'From one-off sessions to ongoing programs, Youlio covers all your mental health needs in a single, secure platform.' },
        { title: 'Personalised Approach', description: 'Youlio enables me to deliver flexible, responsive, and personalised mental health support.' },
      ]"
       />

  <!-- SECTION-CTA -->
      <Wave colorClass="fill-white" shadow position="down" /> 
      <BlockCTA    
      heading="Bring Youlio to Your Organisation"
      message="Ready to explore how Youlio can help your organisation? Reach out today to find out more"
      primaryButtonText="Get In Contact"
      primaryButtonLink="mailto:tom@youliohealth.com?subject=I'm%20Interested%20in%20Your%20Work%20with%20Organisations"
      imageSrc="/img/website/for-organisations-cta.jpg"
      bgClass="bg-white"
      theme="light"
      />

  </div>
</template>

<script>
export default {
  name: "ForOrganisationsPage",
};
</script>

<script setup>
      
import BlockCTA from '../../website/components/BlockCTA' ;
import Wave from '../../website/components/Wave' ;
import BlockHero from '../../website/components/BlockHero' ;
import BlockWhyChoose from '../../website/components/BlockWhyChoose' ;
import BlockThreeCards from '../../website/components/BlockThreeCards' ;
import BlockContent from '../../website/components/BlockContent' ;
import BlockContentInSwiper from '../../website/components/BlockContentInSwiper' ;
import BlockSwiper from '../../website/components/BlockSwiper' ;
import BlockTestimonial from '../../website/components/BlockTestimonial' ;
import BlockStructureTitle from '../../website/components/BlockStructureTitle' ;
import { AcademicCapIcon, BuildingLibraryIcon, BuildingOffice2Icon, KeyIcon, CalendarIcon, CheckIcon, ClipboardIcon } from '@heroicons/vue/24/outline'
import { ChevronRightIcon } from '@heroicons/vue/20/solid' 

</script>
