<template>

  <div class="min-h-screen bg-white overflow-hidden"> 
<!--Blur background-->
    <div class="min-h-screen relative isolate overflow-hidden">
      <div class="absolute -top-80 left-[max(6rem,33%)] -z-10 transform-gpu blur-3xl sm:left-1/2  lg:ml-20 xl:top-3 xl:ml-56" aria-hidden="true">
      <div class="opacity-40 aspect-[801/1036] w-[50.0625rem] bg-gradient-to-tr from-[#6A99BA] to-[#ADD4F0] " style="clip-path: polygon(63.1% 29.6%, 100% 17.2%, 76.7% 3.1%, 48.4% 0.1%, 44.6% 4.8%, 54.5% 25.4%, 59.8% 49.1%, 55.3% 57.9%, 44.5% 57.3%, 27.8% 48%, 35.1% 81.6%, 0% 97.8%, 39.3% 100%, 35.3% 81.5%, 97.2% 52.8%, 63.1% 29.6%)" />
    </div>

    <div class="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80" aria-hidden="true">
        <div class="opacity-20 relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#6A99BA] to-[#ADD4F0] sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]" style="clip-path: polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)" />
      </div>
  <!--END background-->
    
  <Header />
  
    <div class="px-12 pt-8 md:pt-12 pb-8 md:pb-16" data-aos="fade-up" data-aos-once="true" data-aos-duration="1000">
      <h3 class="ws-text-heading-sm text-center text-gray-800">
       <span class="font-light">Take a </span> <br />Mental Health Assessment 
      </h3>
    </div>

    <div class="mx-auto px-6 max-w-2xl">  
<!--Card-->
      <div class="mx-auto md:min-w-xl pt-8 md:pt-12 pb-12 ws-card-bg-white-padding" data-aos="slide-left" data-aos-once="true" data-aos-duration="1000">
                            
<!--Swiper-->
      
<div class="swiper-container">
<swiper
    :pagination="true"
    :navigation="true"
    :modules="modules"
    class="mySwiper"
    style="
    --swiper-pagination-color: #6597B8;
    --swiper-navigation-size: 24px;
    "
  >

<!--Slide1-->
        <swiper-slide class="pb-16">

<!--Avatar and Text-->

<div class="px-6 md:px-16 pt-4 pb-8 flex">
          <div class="inline-block h-12 sm:h-16 w-auto overflow-hidden ring-2 ring-white shadow-md rounded-full">
            <img class="h-12 sm:h-16" src="/img/avatar.jpg" alt="Youlio founder image" />
          </div>
            <div class="my-auto ml-3">
              <p class="text-lg font-semibold text-gray-700 ">Tom Mitchell</p>
              <p class="text-sm font-normal text-gray-600">Therapist & founder of Youlio</p>
            </div>
          </div> 

          <div class="px-6 md:px-16">              
            <p class="ws-text-body-base text-gray-700">
              “This mental health assessment helps you to understand your current mental health status.”            
            </p>
          </div>  
        </swiper-slide>
<!--Slide2-->
        <swiper-slide>
            
            
            <!--Avatar and Text-->
        <div class="px-6 md:px-16 pt-4 pb-8 flex">
          <div class="inline-block h-10 sm:h-14 w-auto">
            <img class="h-10 sm:h-14" src="/img/website/landing/discover.jpg" alt="Youlio founder image" />
          </div>
            <div class="my-auto ml-3">
              <p class="text-xl font-bold text-gray-700 ">1. </p>
            </div>
          </div> 
          <div class="px-6 md:px-16">  
            <p class="ws-text-body-base text-gray-700">
              First, you’ll ‘Discover’ with a quick questionnaire. Your free Youlio account will be created, and you can instantly see your results on your dashboard.            
            </p>
          </div>  
        </swiper-slide>
<!--Slide3-->
        <swiper-slide>
         
            
               <!--Avatar and Text-->
        <div class="px-6 md:px-16 pt-4 pb-8 flex">
          <div class="inline-block h-10 sm:h-14 w-auto">
            <img class="h-10 sm:h-14" src="/img/website/landing/understand.jpg" alt="Youlio founder image" />
          </div>
            <div class="my-auto ml-3">
              <p class="text-xl font-bold text-secondary-500 ">2. Understand</p>
            </div>
          </div> 
          <div class="px-6 md:px-16"> 
            <p class="ws-text-body-base text-gray-700">
              Next, you can book a free 20 minute consultation with me and I'll help you ‘Understand’ your results and suggest some actionable next steps.            </p>
          </div>                 
        </swiper-slide>
<!--Slide4-->
        <swiper-slide>
          
            
               <!--Avatar and Text-->
        <div class="px-6 md:px-16 pt-4 pb-8 flex">
          <div class="inline-block h-10 sm:h-14 w-auto">
            <img class="h-10 sm:h-14" src="/img/website/landing/thrive.jpg" alt="Youlio founder image" />
          </div>
            <div class="my-auto ml-3">
              <p class="text-xl font-bold text-primary-600 ">3. Thrive</p>
            </div>
          </div> 
          <div class="px-6 md:px-16">  
            <p class="ws-text-body-base text-gray-700">
              Beyond the MOT, I offer a number of pathways designed to fit your individual needs. I make sure there's a way for everyone to ‘Thrive’ and move towards better mental health.           
             </p>
          </div>                 
        </swiper-slide>
<!--END Swiper-->
      </swiper>
      </div>
    
        
<!--CTA--> 
        <div class="px-6 pt-6 pb-3 md:max-w-sm mx-auto">
          <router-link to="/register/start">
            <button type="submit" class="ws-cta-primary">
              Let's Go
            </button>
          </router-link>
        </div>
        
      </div>
    </div>
  </div>
    </div>

</template>
    
<script setup>
import { onMounted } from 'vue'
import Header from '../../website/components/Header';
import { useRouter } from 'vue-router';

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";
// Import Swiper styles
import "swiper/css";

import "swiper/css/pagination";
import 'swiper/css/navigation';

// import required modules
import { Pagination, Navigation } from "swiper";

const router = useRouter()

    function goBack() {
        return router.go(-1)
}

onMounted(() => {
  sessionStorage.setItem('register_type', 'mot');
})

const modules = [Pagination, Navigation]

</script>

<style>
/* Custom CSS to position the swiper navigation arrows */
.swiper-container {
  position: relative;
  overflow: visible; /* Ensure the navigation arrows are visible outside the container */
  z-index: 1; /* Set z-index to 1 to make sure the arrows are behind the swiper slides */
}

.swiper-button-prev,
.swiper-button-next {
  position: absolute;
  top: 40%;
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
  color: #6597B8;
  z-index: 2; /* Ensure the arrows appear above the swiper slides */
  
  cursor: pointer;
}

/* Adjust the positioning of the arrows */
.swiper-button-prev {
  left: 5px;
}

.swiper-button-next {
  right: 5px;
}

/* Media query to hide navigation arrows on mobile */
@media (max-width: 768px) {
  .swiper-button-prev,
  .swiper-button-next {
    display: none;
  }
}
</style>
  