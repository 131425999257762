
import axios from "axios";

export const state = {
  behaviours: [],
  thoughts: [],
  feelings: [],
  problems: [],
  sensations: [],
  symptoms: [],
  characters: [],
  values: [],
  lifeslices: [],
  lifetraps: [],
  emotionalstates: [],
  parentstyles: [],
};

export const mutations = {
  
  set_behaviours: (state, behaviours) => {
    state.behaviours = behaviours;
  },
  set_thoughts: (state, thoughts) => {
    state.thoughts = thoughts;
  },
  set_feelings: (state, feelings) => {
    state.feelings = feelings;
  },
  set_problems: (state, problems) => {
    state.problems = problems;
  },
  set_sensations: (state, sensations) => {
    state.sensations = sensations;
  },
  set_symptoms: (state, symptoms) => {
    state.symptoms = symptoms;
  },



  set_characters: (state, characters) => {
    state.characters = characters;
  },

  set_values: (state, values) => {
    state.values = values;
  },

  set_lifeslices: (state, lifeslices) => {
    state.lifeslices = lifeslices;
  },

  set_lifetraps: (state, lifetraps) => {
    state.lifetraps = lifetraps;
  },

  set_emotionalstates: (state, emotionalstates) => {
    state.emotionalstates = emotionalstates;
  },

  set_parentstyles: (state, parentstyles) => {
    state.parentstyles = parentstyles;
  },

  
};
export const actions = {
  

  async fetchBehaviours({ commit }) {
    try {
      const response = await axios.get(
        "/api/me/behaviours"
      );
      commit("set_behaviours", response.data.behaviours);
    } catch (error) {
      alert(error);
      console.log(error);
    }
  },

  async fetchThoughts({ commit }) {
    try {
      const response = await axios.get(
        "/api/me/thoughts"
      );
      commit("set_thoughts", response.data.thoughts);
    } catch (error) {
      alert(error);
      console.log(error);
    }
  },

  async fetchFeelings({ commit }) {
    try {
      const response = await axios.get(
        "/api/me/feelings"
      );
      commit("set_feelings", response.data.feelings);
    } catch (error) {
      alert(error);
      console.log(error);
    }
  },
  async fetchProblems({ commit }) {
    try {
      const response = await axios.get(
        "/api/me/problems"
      );
      commit("set_problems", response.data.problems);
    } catch (error) {
      alert(error);
      console.log(error);
    }
  },
  async fetchSensations({ commit }) {
    try {
      const response = await axios.get(
        "/api/me/sensations"
      );
      commit("set_sensations", response.data.sensations);
    } catch (error) {
      alert(error);
      console.log(error);
    }
  },
  async fetchSymptoms({ commit }) {
    try {
      const response = await axios.get("/api/me/symptoms");
      commit("set_symptoms", response.data.symptoms);
    } catch (error) {
      alert(error);
      console.log(error);
    }
  }, 




  async fetchCharacters({ commit }) {
    try {
      const response = await axios.get("/api/me/characters");
      commit("set_characters", response.data.characters);
    } catch (error) {
      alert(error);
      console.log(error);
    }
  }, 

  async fetchValues({ commit }) {
    try {
      const response = await axios.get("/api/me/values");
      commit("set_values", response.data.values);
    } catch (error) {
      alert(error);
      console.log(error);
    }
  }, 

  async fetchLifeslices({ commit }) {
    try {
      const response = await axios.get("/api/me/lifeslices");
      commit("set_lifeslices", response.data.lifeslices);
    } catch (error) {
      alert(error);
      console.log(error);
    }
  }, 

  async fetchLifeslices({ commit }) {
    try {
      const response = await axios.get("/api/me/lifeslices");
      commit("set_lifeslices", response.data.lifeslices);
    } catch (error) {
      alert(error);
      console.log(error);
    }
  },

  async fetchLifetraps({ commit }) {
    try {
      const response = await axios.get("/api/me/lifetraps");
      commit("set_lifetraps", response.data.lifetraps);
    } catch (error) {
      alert(error);
      console.log(error);
    }
  }, 

  async fetchEmotionalstates({ commit }) {
    try {
      const response = await axios.get("/api/me/emotionalstates");
      commit("set_emotionalstates", response.data.emotionalstates);
    } catch (error) {
      alert(error);
      console.log(error);
    }
  }, 

  async fetchParentstyles({ commit }) {
    try {
      const response = await axios.get("/api/me/parentstyles");
      commit("set_parentstyles", response.data.parentstyles);
    } catch (error) {
      alert(error);
      console.log(error);
    }
  } 




};
export const getters = {
  
  ['getBehaviours'](state) {
    return state.behaviours;
  },
  ['getThoughts'](state) {
    return state.thoughts;
  },
  ['getFeelings'](state) {
    return state.feelings;
  },
  ['getProblems'](state) {
    return state.problems;
  },
  ['getSensations'](state) {
    return state.sensations;
  },
  ['getSymptoms'](state) {
    return state.symptoms;
  },



  ['getCharacters'](state) {
    return state.characters;
  },
  ['getValues'](state) {
    return state.values;
  },
  ['getLifeslices'](state) {
    return state.lifeslices;
  },
  ['getLifetraps'](state) {
    return state.lifetraps;
  },
  ['getEmotionalstates'](state) {
    return state.emotionalstates;
  },
  ['getParentstyles'](state) {
    return state.parentstyles;
  }


};

export default {
  state,
  actions,
  mutations,
  getters
};
