<template>

<div class="bg-secondary-600 pt-16 pb-8 sm:pb-20">
    <div class="mx-auto max-w-3xl px-6 text-center lg:px-8">
      <div class="mx-auto max-w-3xl">
        <h2 class="mt-2 ws-text-heading-md text-white">Pricing</h2>
      </div>
      <div class="relative mt-6">
        <p class="mx-auto ws-text-body-base text-white/90">Enhance your mental health through personalised programs that combine expert guidance, tailored sessions, and practical resources to support long-term well-being and growth.</p>
      </div>
    </div>
    <div class="bg-secondary-600 py-16 px-6">
      <div class="mx-auto max-w-2xl rounded-3xl ring-1 ring-gray-200 lg:flex lg:max-w-6xl ">
        <div class="p-8 sm:p-10 lg:flex-auto">
          <p class="mt-6 text-base leading-7 text-white">Choose a program and the duration of either 6, 9 or 12 weeks.</p>
          <div class="mt-10 flex items-center gap-x-4">
            <h4 class="flex-none text-sm font-semibold leading-6 text-white">Each program includes:</h4>
            <div class="h-px flex-auto bg-gray-100" />
          </div>
          <ul role="list" class="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-white sm:grid-cols-2 sm:gap-6">
            <li v-for="feature in includedFeatures" :key="feature" class="flex gap-x-3">
              <CheckIcon class="h-6 w-5 flex-none text-white" aria-hidden="true" />
              {{ feature }}
            </li>
          </ul>
        </div>
        <div class="-mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0">
          <div class="rounded-2xl bg-gray-50 py-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-16">
            <div class="mx-auto max-w-xs px-8">
              <p class="text-base font-semibold text-gray-600">Payment is weekly</p>
              <p class="mt-6 flex items-baseline justify-center gap-x-2">
                <span class="text-5xl font-bold tracking-tight text-gray-900">£50</span>
                <span class="text-sm font-semibold leading-6 tracking-wide text-gray-600">/Week</span>
              </p>
              <a href="/register/overview-programs" class="mt-10 block w-full ws-cta-primary">Get started</a>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
 


</template>


<script setup>
      
import Wave from '../../website/components/Wave' ;
import BlockStructureTitle from '../../website/components/BlockStructureTitle' ;
import { CheckIcon, CalendarIcon, ClipboardIcon, KeyIcon } from '@heroicons/vue/24/outline' 

const includedFeatures = [
  '30-minute 1:1 weekly therapy sessions',
  'Ongoing communication and support througout the program',
  'Access to all of Youlios features and resources',
]

</script>
