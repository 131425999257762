<template>
   
  <div v-show="!loading" class="min-h-screen">
    
<!-- Header -->	
    <header class="header-container">
     
      <div class="flex items-center">
        <router-link to="/dashboard">
        <ChevronLeftIcon class="flex items-center lg:hidden chevron-header-dark -ml-2" />
      </router-link>
        <h1 class="text-page-title-dark">My Mental Health</h1>
      </div>  
    
      <div class="flex">
        <info-icon>
          <template v-slot:content>
          <p class="introcard-title-text">
            Mental Health Tracking
          </p>
          <p class="introcard-body-text">
            {{ description }}   
          </p>  
          </template>
        </info-icon>
      </div>         
  </header>
<!-- END Header -->	

<div class="overflow-x-hidden">
  <main class="main-layout" >     
<!--Next Check In-->
    <div class="card-bg-white-padding">
    <div class="flex items-center">
<!-- Column1 -->
      <div class="items-center">
        <img class="h-14" src="/img/webapp/tracking.png" alt="Bar chart" />
        </div>
        <div class="pl-4 items-center text-display-base">
          <p v-if="untilRepeat !== 0">{{ untilRepeat }} DAYS <span class="text-body-base">until next 'Check In'</span></p>
          <p v-else>Check-In is now READY!</p>
        </div>
      </div>
      <div v-if="untilRepeat === 0">
        <div class="my-2 flex items-baseline">
        <!-- CTA -->
          <button class="w-full mx-10 py-3 rounded-md bg-primary mx-auto" @click="startCheckIn">
            <p class="text-base font-medium uppercase text-white">{{ notFinished ? 'Continue' : 'Start' }}</p>
          </button>
        </div>
      </div>
    </div>

    <psychological-health-factor-history v-for="chart in charts" :data="chart"/>
    <div class="my-6"></div>
  </main>
  </div>
</div>

</template>
  
<script setup>

import { ChevronLeftIcon } from '@heroicons/vue/24/outline'
import InfoIcon from '../components/InfoIcon';
import PsychologicalHealthFactorHistory from '../components/PsychologicalHealthFactorHistory';
import { sendPost } from '../components/entities/functions.js';
</script>

<script>
export default {
  data: function () {
    return {
      title: '',
      description: '',
      slug: '',
      untilRepeat: 0,
      charts: {},
      loading: true,
      notFinished: false
    }
  },

  mounted() {
    this.slug = this.$route.params.slug;
    this.getData();
  },

  methods: {
    async getData() {
      const responce = await sendPost('/api/collections/' + this.slug);
      if (responce) {
        this.title = responce.title;
        this.description = responce.description;
        this.untilRepeat = responce.until_repeat;
        this.charts = responce.questionnaires;
        this.notFinished = responce.not_finished;
        this.loading = false;
      }
    },

    async startCheckIn() {
      const responce = await sendPost('/api/collections/' + this.slug + '/start');
      if (responce) {
        let questionnaireInstanceId = responce.questionnaire_instance_id;
        this.$router.push({ name: 'ActivityQuestionnaireInstanceStart', params: { questionnaireinstanceid: questionnaireInstanceId } });
      }
    }
  }
}
</script>
