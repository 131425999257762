<template>
  <TransitionRoot appear :show="isOpen" as="template">
    <Dialog as="div" @close="closeModal" class="relative z-40">
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black bg-opacity-90" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div class="flex min-h-full items-center justify-center p-4 text-center">
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <DialogPanel
              class="bg-white rounded-xl w-full max-w-sm transform overflow-hidden text-left align-middle transition-all"
            >
              <DialogTitle
                as="h3"
                class="px-8 py-4 text-left text-display-base bg-secondary-50"
              >
                {{ title }}
              </DialogTitle>
              <div v-for="(text, index) in content" class="mt-6 px-8">
                <hr v-if="text == '' && index < content.length - 1" class="h-px my-4 bg-gray-200 border-0">
                <p v-else class="text-body-base" v-html="text"></p>
              </div>

              <div class="flex items-center justify-center px-16 mt-8 mb-8 max-w-sm mx-auto">
                <button type="button" @click="closeModal" class="button-information-modal">
                  Got it, thanks!
                </button>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
  
<script setup>
  import {
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogPanel,
    DialogTitle,
  } from '@headlessui/vue'
</script>

<script>
export default {
  data: function () {
    return {
      isOpen: false,
      title: String,
      content: String
    }
  },

  inject: ['informationModalActions'],

  mounted() {
    if (this.informationModalActions) {
      this.informationModalActions.openModal = this.openModal;
      this.informationModalActions.closeModal = this.closeModal;
    }
  },

  beforeUnmount() {
    if (this.informationModalActions) {
      this.informationModalActions.openModal = null;
      this.informationModalActions.closeModal = null;
    }
  },

  methods: {
    closeModal() {
      this.isOpen = false;
    },

    openModal(title, text) {
      if (!Array.isArray(text)) {
        text = [text];
      }

      this.title = title;
      this.content = this.splitSentences(text);
      this.isOpen = true;
    },

    splitSentences(arr) {
      const result = [];
      arr.forEach(item => {
        const sentences = item.split('. ');
        sentences.forEach(sentence => {
          result.push(sentence);
        });
        result.push('');
      });
      return result;
    }
  },
}
</script>
  