<template>
  <div class="mental-health-programs-page">
  
<!-- SECTION-Hero -->
<section class="relative bg-gradient-to-r from-primary-600 to-primary-500" data-aos="fade-up" data-aos-once="true" data-aos-duration="1000">
        <div class="mx-auto max-w-7xl lg:grid lg:grid-cols-12 lg:gap-x-8">
            <div class="px-6 pb-4 pt-16 sm:pb-0 lg:col-span-7 lg:px-0 lg:pb-24 lg:pt-20 xl:col-span-6">
                <div class="mx-auto max-w-2xl lg:mx-0">
                    <h1 class="text-center sm:text-left mt-4 sm:mt-8 text-4xl font-bold text-white sm:text-5xl">Mental Health Programs</h1>
                    <p class="text-center sm:text-left mt-4 text-lg text-white sm:text-xl">Youlio Mental Health Programs offer structured, goal-oriented plans designed to address your unique mental health needs. Each program includes personalised 1:1 therapy with me, ensuring that our work together is tailored specifically to you.</p>
                    <div class="mt-8 sm:mt-10 flex flex-col space-y-4 sm:flex-row sm:space-y-0 sm:space-x-4">
          <!-- Button1 -->
          <a href="/register/overview-programs" class="w-56 mx-auto sm:mx-0 text-center rounded-full bg-secondary-400 px-4 py-3 text-lg font-medium tracking-wide text-white shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600">
            Get Started  
          </a>
        </div>
          </div>
            </div>
            <div class="pb-8 sm:pt-12 relative lg:col-span-5 lg:-mr-8 xl:absolute xl:inset-0 xl:left-1/2 xl:mr-0">
                <div class="sm:py-8 sm:px-8  relative w-full h-0" style="padding-bottom:50%;">
                    <img class="sm:px-16 absolute inset-0 w-full h-full object-contain object-center" src="/img/website/hero-weekly.png" alt="" />
                </div>
            </div>
        </div>
    </section>

<!-- SECTION-Benefits of Programs -->
      <BlockWhyChoose
      overline="Personalised Support"
      headline="Benefits of a Mental Health Program"
      subheadline="Mental Health Programs offer a structured path with clear goals, helping you make real progress through targeted activities and guidance tailored just for you."
      listImageSrc="/img/website/programs-benefits.jpg"
      listImageAlt="Why Choose Us"
      :listItems="[
        { title: 'Clear Path Forward', description: 'Structured programs with specific goals help you see and track your progress, making your journey feel more purposeful.' },
        { title: 'Customised Plans', description: 'Tailored activities and strategies fit your unique needs, ensuring the support you get is just right for you.' },
        { title: 'Ongoing Guidance', description: 'Regular check-ins and updates from me keep you on track and adjust your program as needed for continuous improvement.' },
      ]"
      />

<!-- SECTION-What Does Your Journey Look Like -->
      <Wave colorClass="fill-white" shadow position="down" /> 
      <BlockSwiper
        :slides="3"
        overline="What To Expect"
        headline="How Your Journey Will Look"
        subheadline="My Mental Health Programs are structured journeys designed to create lasting change, available in 6, 9, or 12-week options. Throughout your program, you’ll have regular 1:1 therapy sessions with me, ensuring that every step is tailored to your unique needs."
>
        <template #slide-0>
          <BlockContentInSwiper
        headline="1. Initiate"
        subheadline="At the start, we’ll focus on understanding your current situation and clearly defining the goals of your chosen program. This phase includes comprehensive assessments and initial therapy sessions to create a strong foundation for your journey."
        imageSrc="/img/website/programs-initiate.jpg"
        bgClass="bg-white"
        theme="light"
        layout="image-right"
        paddingTopClass="pt-4 sm:pt-4 md:pt-4"
        paddingBottomClass="pb-4 sm:pb-4 md:pb-4"
        />
        </template>
        <template #slide-1>
          <BlockContentInSwiper
        headline="2. Engage"
        subheadline="In this phase, we dive deep into the core of your chosen focus area. We’ll work through challenges, identify patterns, and implement strategies tailored to your specific needs. Through regular sessions and targeted exercises, you'll begin to see meaningful progress."
        imageSrc="/img/website/programs-engage.jpg"
        bgClass="bg-white"
        theme="light"
        layout="image-right"
        paddingTopClass="pt-4 sm:pt-4 md:pt-4"
        paddingBottomClass="pb-4 sm:pb-4 md:pb-4"
        />
        </template>
        <template #slide-2>
          <BlockContentInSwiper
        headline="3. Transform"
        subheadline="As you near the end of your program, we’ll focus on solidifying the progress you’ve made and equipping you with tools for sustained well-being. We’ll refine your strategies and develop a long-term plan to support your continued growth beyond the program."
        imageSrc="/img/website/programs-transform.jpg"
        bgClass="bg-white"
        theme="light"
        layout="image-right"
        paddingTopClass="pt-4 sm:pt-4 md:pt-4"
        paddingBottomClass="pb-4 sm:pb-4 md:pb-4"
        />
        </template>
      </BlockSwiper>

<!-- SECTION-Testimonials -->
      <BlockTestimonial
  :testimonials="[
    
  {
    text: 'Tom’s program helped me create lasting changes in my mental wellbeing. The practical strategies were so valuable to me. Highly recommended!',
    name: 'Emily',
    age: 27
  },
  {
    text: 'Joining Tom’s program reduced my anxiety and helped me find balance again. A truly worthwhile experience!',
    name: 'Liam',
    age: 32
  },
  {
    text: 'Tom’s mental health program helped me overcome burnout and prioritise self-care. I’m now better equipped to handle challenges.',
    name: 'Sophia',
    age: 39
  }


  ]"
  sectionBgColor="bg-secondary-600"
      />


<!-- SECTION-Example Programs -->
      <BlockSwiper
        :slides="4"
        overline="What Do They Look Like?"
        headline="Example Programs"
        subheadline="Explore some of the tailored programs designed to address common mental health challenges."
>
        <template #slide-0>
          <BlockContentInSwiperProgram
        headline="Optimising Your Mental Health"
        subheadline="Enhance your overall well-being with tailored strategies for balance and resilience. This program focuses on practical tools and techniques to optimise your mental health for a more fulfilling life."
        imageSrc="/img/website/program-mental-health.jpg"
        bgClass="bg-white"
        theme="light"
        layout="image-right"
        paddingTopClass="pt-4 sm:pt-4 md:pt-4"
        paddingBottomClass="pb-4 sm:pb-4 md:pb-4"
        />
        </template>
        <template #slide-1>
          <BlockContentInSwiperProgram
        headline="Boosting Your Self-esteem"
        subheadline="Transform the way you see yourself and build a stronger sense of self-worth. This program guides you through exercises and therapy sessions designed to challenge negative self-perceptions and foster a positive, confident mindset."
        imageSrc="/img/website/program-self-esteem-1.jpg"
        bgClass="bg-white"
        theme="light"
        layout="image-right"
        paddingTopClass="pt-4 sm:pt-4 md:pt-4"
        paddingBottomClass="pb-4 sm:pb-4 md:pb-4"
        />
        </template>
        <template #slide-2>
          <BlockContentInSwiperProgram
        headline="Managing Your Anxiety"
        subheadline="Learn to take control of your anxiety and reduce its impact on your daily life. This program provides practical tools and therapeutic techniques to help you manage anxious thoughts and feelings, empowering you to live more freely."
        imageSrc="/img/website/program-anxiety.jpg"
        bgClass="bg-white"
        theme="light"
        layout="image-right"
        paddingTopClass="pt-4 sm:pt-4 md:pt-4"
        paddingBottomClass="pb-4 sm:pb-4 md:pb-4"
        />
        </template>
        <template #slide-3>
          <BlockContentInSwiperProgram
        headline="Navigating Life Transitions"
        subheadline="Whether you're changing careers, moving to a new city, or experiencing a significant life event, this program helps you navigate transitions smoothly. Through structured support and personalised strategies, you’ll find clarity and stability during times of change."
        imageSrc="/img/website/program-transitions.jpg"
        bgClass="bg-white"
        theme="light"
        layout="image-right"
        paddingTopClass="pt-4 sm:pt-4 md:pt-4"
        paddingBottomClass="pb-4 sm:pb-4 md:pb-4"
        />
        </template>
       
      </BlockSwiper>

      <!-- SECTION-Pricing -->


<BlockPricingPrograms />

<!-- SECTION-Getting Started -->

<BlockGettingStarted
        title="Getting Started"
        bgClass="bg-white"
        :steps="[
          { icon: UserIcon, title: 'Step 1: Create a Free Youlio Account', description: 'Get started by creating your free Youlio account. This will allow you to book a free consultation and meet me.' },
          { icon: CalendarIcon, title: 'Step 2: Book a Consultation', description: 'Schedule a consultation to discuss your needs and expectations. We’ll explore how I can best support your mental health journey.' },
          { icon: RocketLaunchIcon, title: 'Step 3: Start Your Program', description: 'Begin your program with personalised guidance and therapy from me, working together to achieve your mental health goals.' }
        ]"
      />

<!-- SECTION-CTA -->
    <Wave colorClass="fill-white" shadow position="down" /> 
    <BlockCTA    
      heading="Ready to Transform Your Mental Health?"
      message="Reach out today to start your journey towards a more focused and fulfilling path."
      primaryButtonText="Get Started"
      primaryButtonLink="/register/overview-programs"
      secondaryButtonText="Contact Me"
      secondaryButtonLink="mailto:tom@youliohealth.com?subject=I'm%20Interested%20in%20Your%20Mental%20Health%20Programs"
      bgClass="bg-white"
      theme="light"
      imageSrc="/img/website/programs-cta.jpg"
    />

  </div>
</template>

<script>
export default {
  name: "MentalHealthProgramsPage",
};
</script>

<script setup>
      
import BlockCTA from '../../website/components/BlockCTA' ;
import Wave from '../../website/components/Wave' ;
import BlockHero from '../../website/components/BlockHero' ;
import BlockWhyChoose from '../../website/components/BlockWhyChoose' ;
import BlockContentInSwiper from '../../website/components/BlockContentInSwiper';
import BlockPricingPrograms from '../../website/components/BlockPricingPrograms';
import BlockContentInSwiperProgram from '../../website/components/BlockContentInSwiperProgram';
import BlockContent from '../../website/components/BlockContent' ;
import BlockSwiper from '../../website/components/BlockSwiper' ;
import BlockTestimonial from '../../website/components/BlockTestimonial' ;
import BlockGettingStarted from '../../website/components/BlockGettingStarted' ;
import BlockStructureTitle from '../../website/components/BlockStructureTitle' ;
import { RocketLaunchIcon, UserIcon, CheckIcon, CalendarIcon, ClipboardIcon, KeyIcon } from '@heroicons/vue/24/outline' 

</script>
