<template>

<!-- Header -->	
    <header class="header-container">
      <div class="flex items-center">
        <router-link to="/dashboard">
        <ChevronLeftIcon class="flex items-center lg:hidden chevron-header-dark -ml-2" />
      </router-link>
        <h1 class="text-page-title-dark">Deep Dive</h1>
      </div>
    
      <div class="flex">
        <info-icon>
          <template v-slot:content>
          <p class="introcard-title-text">
            Your Deep Dive
          </p>
          <p class="introcard-body-text">
            When you complete questionnaires the results will appear in your deep dive. This keeps all your findings in one easy to access location.
          </p>
          </template>
        </info-icon>
      </div>
  </header>
<!-- END Header -->	

<loading-overlay :loading="loading"></loading-overlay>
<div class="overflow-x-hidden">
<!--Title&Filter-->
<main v-if="!loading" class="main-layout" data-aos="slide-left" data-aos-once="true" data-aos-duration="250">
    
    <div class="my-4" >

    <div v-if="Object.keys(results).length">
      <deep-dive-values v-for="result in results" :data="result" />
    </div>
    <div v-else class="card-no-entries">
      <p>There are currently no entries in your 'Deep Dive'.</p>
    </div>

      </div>
    </main>
  </div>
</template>

<script setup>

  import { ref } from 'vue'
  import { ChevronLeftIcon  } from '@heroicons/vue/24/outline'
  import InfoIcon from '../components/InfoIcon';
  import DeepDiveValues from '../components/DeepDiveValues';
  import { sendPost } from '../components/entities/functions.js';
  import LoadingOverlay from '../components/LoadingOverlay';

</script>

<script>
export default {
  data: function () {
    return {
        results: {},
        loading: false
    }
  },

  mounted() {
    this.getResults();
  },

  methods: {
    async getResults() {
      this.loading = true;
      const responce = await sendPost('/api/deep-dive');
      if (responce) {
        this.results = responce.results;
      }
      this.loading = false;
    }
  }
}
</script>
